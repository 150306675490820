import * as actions from '../actions/downloadActions';

export interface DownloadState {
    isFetching: boolean;
    downloadedInvoiceUrl: string | null;
}

const defaultDownloadState = {
    isFetching: false,
    downloadedInvoiceUrl: null,
};

export const downloadReducer = (
    state: DownloadState = defaultDownloadState,
    action: actions.DownloadActions,
) => {
    switch (action.type) {
        case actions.GET_BENEFITS_CSV_REQUESTED:
        case actions.GET_CONTRACTS_CSV_REQUESTED:
            return {
                ...state,
                isFetching: true,
            };
        case actions.GET_BENEFITS_CSV_SUCCEED:
        case actions.GET_CONTRACTS_CSV_SUCCEED:
        case actions.DELAYED_CSV_DOWNLOAD:
            return {
                isFetching: false,
            };
        case actions.GET_BENEFITS_CSV_FAILED:
        case actions.GET_CONTRACTS_CSV_FAILED:
            return {
                ...state,
                isFetching: false,
            };
        case actions.DOWNLOAD_INVOICE_PDF_SUCCEED:
            return {
                ...state,
                isFetching: false,
                downloadedInvoiceUrl: action.payload,
            };
        case actions.CLEAR_INVOICE_URL:
            return {
                ...state,
                isFetching: false,
                downloadedInvoiceUrl: null,
            };
        default:
            return state;
    }
};
