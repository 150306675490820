import * as React from 'react';
import posthog from 'posthog-js';

import { SubmitButton } from 'common/components';
import { CenteringWrapper, Header, MarginLink } from 'common/components/styled';
import { POSTHOG_API_KEY, SENTRY_ENVIRONMENT } from 'config/env';
import {
    Antd3Form,
    Antd3FormProps,
    Antd3Icon,
    Antd3Input,
} from 'common/components/deprecated/antd3';
import { UserPaths } from 'features/user/models/userModel';

import { useDispatch, useSelector } from 'react-redux';
import { getIsUserLoggedIn } from 'features/user/selectors/userSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';

import { AuthContainerProps } from '../containers/AuthContainer';

if (SENTRY_ENVIRONMENT === 'frontend-production') {
    if (POSTHOG_API_KEY) {
        posthog.init(POSTHOG_API_KEY, {
            api_host: 'https://eu.i.posthog.com',
            autocapture: false,
            capture_pageview: false,
        });
    }
}

type AuthFormProps = AuthContainerProps & Antd3FormProps;

const AuthForm = ({ form, logInRequest, isProcessing }: AuthFormProps) => {
    const dispatch = useDispatch();

    const isUserLoggedIn = useSelector(getIsUserLoggedIn);

    React.useEffect(() => {
        if (isUserLoggedIn) {
            dispatch(redirectToUrl('/'));
        }
    }, [isUserLoggedIn]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        form.validateFields((error, { email, password }) => {
            if (!error) {
                logInRequest({ username: email.toLowerCase(), password });
            }
        });
    };

    return (
        <CenteringWrapper>
            <Antd3Form onSubmit={handleSubmit}>
                <Header>Login</Header>
                <Antd3Form.Item>
                    <Antd3Input
                        form={form}
                        id="email"
                        getFieldDecoratorOptions={{
                            rules: [{ required: true, message: 'Please enter your email address' }],
                        }}
                        prefix={<Antd3Icon type="user" />}
                        placeholder="Email address"
                        disabled={isProcessing}
                    />
                </Antd3Form.Item>
                <Antd3Form.Item>
                    <Antd3Input
                        form={form}
                        id="password"
                        getFieldDecoratorOptions={{
                            rules: [{ required: true, message: 'Please enter your password' }],
                        }}
                        prefix={<Antd3Icon type="lock" />}
                        type="password"
                        placeholder="Password"
                        disabled={isProcessing}
                    />
                </Antd3Form.Item>
                <SubmitButton loading={isProcessing} value="Login" />
            </Antd3Form>
            <MarginLink to={UserPaths.ResetPassword}>I have forgotten my password.</MarginLink>
        </CenteringWrapper>
    );
};

export const Auth = Antd3Form.create({})(AuthForm);
