import * as React from 'react';
import { Modal, Button } from 'antd';
import { connect } from 'react-redux';
import { isUndefined } from 'lodash';

import { AppState } from 'common/appState';
import { CenteringFormItem } from 'common/components/styled';
import { InviteExecutiveUserForm } from 'features/invite/components/InviteExecutiveUserForm';
import { isPhoneNumberValid } from 'common/helpers/phoneValidator';
import { showNotification } from 'common/helpers/notifications';
import {
    InviteByEmailWithRole,
    InvitedExecutive,
    EditBenefitInviteByEmailWithRole,
} from 'features/invite/models/inviteModel';
import { BenefitRole } from 'features/benefit/models/benefitModels';
import { getselectedOrganisationInvitedIndividualExecutive } from 'features/invite/selectors/inviteSelectors';
import { getInvitedIndividualExecutiveRequest } from 'features/invite/actions/inviteActions';
import { Antd3FormProps } from 'common/components/deprecated/antd3';

interface InviteUserModalProps {
    onInvite: (employee: InviteByEmailWithRole | EditBenefitInviteByEmailWithRole) => void;
    organisationName: string;
    organisationId: number;
    role: BenefitRole;
    contractId?: number;
}

interface InviteUserModalStateProps {
    selectedOrganisationInvitedIndividualExecutives: InvitedExecutive[];
}

interface InviteUserModalDispatchProps {
    getInvitedIndividualExecutiveRequest: typeof getInvitedIndividualExecutiveRequest;
}

type Props = InviteUserModalProps &
    InviteUserModalStateProps &
    InviteUserModalDispatchProps &
    Antd3FormProps;

interface InviteUserModalState {
    visible: boolean;
    first_name: string;
    last_name: string;
    email: string;
    telephone: string;
}

export class InviteUserModalComponent extends React.Component<Props> {
    public state: InviteUserModalState = {
        visible: false,
        first_name: '',
        last_name: '',
        email: '',
        telephone: '',
    };

    private showModal = () => {
        this.setState({ visible: true });
        this.props.getInvitedIndividualExecutiveRequest(this.props.organisationId);
    };

    private closeModal = () => {
        this.setState({ visible: false });
    };

    private handleSubmit = () => {
        if (this.state.first_name == '') {
            showNotification({ text: 'First name must not be empty' });
            return;
        }

        if (this.state.last_name == '') {
            showNotification({ text: 'Last name must not be empty' });
            return;
        }

        if (this.state.email == '') {
            showNotification({ text: 'Email must not be empty' });
            return;
        }

        if (
            this.props.selectedOrganisationInvitedIndividualExecutives.filter(
                (invitedExecutive) => invitedExecutive.email == this.state.email,
            ).length > 0
        ) {
            showNotification({
                text:
                    'There is a pending invitation for this user to join ' +
                    this.props.organisationName +
                    ". Please select the user from 'Add Invited " +
                    (this.props.role == BenefitRole.SUPPLIER ? 'Supplier ' : 'Delivery Partner ') +
                    "Users'",
            });
            return;
        }

        if (this.state.telephone !== '' && !isPhoneNumberValid(this.state.telephone)) {
            showNotification({ text: 'Invalid telephone number' });
            return;
        }

        const { visible, email, ...employeeData } = this.state;

        isUndefined(this.props.contractId)
            ? this.props.onInvite({
                  ...employeeData,
                  organisationId: this.props.organisationId,
                  role: this.props.role,
                  email: email.toLowerCase(),
              })
            : this.props.onInvite({
                  ...employeeData,
                  organisationId: this.props.organisationId,
                  role: this.props.role,
                  email: email.toLowerCase(),
                  contract: this.props.contractId,
              });

        this.closeModal();
    };

    private handleChange = (event: React.FormEvent<HTMLFormElement>) => {
        const value = event.currentTarget.value;

        this.setState({
            [event.currentTarget.name]: value.trim(),
        });
    };

    public render(): JSX.Element {
        const { organisationName, form } = this.props;

        const { visible } = this.state;

        return (
            <>
                <CenteringFormItem>
                    <Button
                        type="primary"
                        onClick={this.showModal}
                        disabled={organisationName == undefined}
                    >
                        Invite new user
                    </Button>
                </CenteringFormItem>

                {visible ? (
                    <Modal
                        visible={visible}
                        title={`Invite a new user ${organisationName}`}
                        okText="Invite New User"
                        onCancel={this.closeModal}
                        onOk={this.handleSubmit}
                    >
                        <InviteExecutiveUserForm
                            form={form}
                            handleChange={this.handleChange}
                            organisationName={organisationName}
                        />
                    </Modal>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state: AppState): InviteUserModalStateProps => ({
    selectedOrganisationInvitedIndividualExecutives:
        getselectedOrganisationInvitedIndividualExecutive(state),
});

export const InviteUserModal = connect(mapStateToProps, {
    getInvitedIndividualExecutiveRequest,
})(InviteUserModalComponent);
