import moment from 'moment';
import { startCase, camelCase } from 'lodash';

import { DateFormat } from 'config/variables';
import { Benefit, BenefitOrganisationRole } from 'features/benefit/models/benefitModels';
import { formatOrganisationsNamesForDisplay } from './utils';

export const getListData = (
    benefit: Benefit,
    showFinalDeliveryDate: boolean,
    benefitSuppliers: BenefitOrganisationRole[],
    benefitPartners: BenefitOrganisationRole[],
) => {
    const finalDeliveryDate = `Benefit Completion Date: ${moment(benefit.finalDeliveryDate).format(
        DateFormat.Euro,
    )}`;
    const dataArray = [
        `Benefit: ${benefit.outcome}` +
            `${benefit.referenceNumber ? ` | ${benefit.referenceNumber}` : ``}`,
        `Outcome: ${benefit.outcome}`,
        `Benefit Label: ${benefit.label ? benefit.label : 'none'}`,
        `Benefit Description: ${benefit.description}`,
        `Benefit Points: ${benefit.points ? benefit.points : 'none'}`,
        `Benefit Value: ${benefit.value ? benefit.value : 'none'}`,
        `Emission Savings: ${benefit.emissionSavings ? benefit.emissionSavings : 'none'}`,
        `Quantity: ${benefit.quantity}`,
        `Benefit Status: ${startCase(camelCase(benefit.status))}`,
    ];
    if (benefitSuppliers && benefitSuppliers.length) {
        dataArray.splice(
            dataArray.length - 1,
            0,
            `Suppliers: ${formatOrganisationsNamesForDisplay(benefitSuppliers)}`,
        );
    }
    if (benefitPartners && benefitPartners.length) {
        dataArray.splice(
            dataArray.length - 1,
            0,
            `Delivery Partners: ${formatOrganisationsNamesForDisplay(benefitPartners)}`,
        );
    }

    if (showFinalDeliveryDate) {
        dataArray.splice(dataArray.length - 1, 0, finalDeliveryDate);
    }

    return dataArray.map((name: string, id: number) => ({
        id,
        name,
    }));
};
