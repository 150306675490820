import { createSelector } from 'reselect';

import { AppState } from 'common/appState';
import { Contract, ContractRole, ContractRoleData } from 'features/contract/models/contractModels';
import { extractRouteId } from 'common/selectors/utilitySelectors';
import {
    getBenefitByRouteId,
    getMyBenefitByRouteId,
    filterArchived,
} from 'features/benefit/selectors/benefitSelectors';
import { getUserId, getAllEmployees } from 'features/user/selectors/userSelectors';
import { getExecutives } from 'features/organisation/selectors/organisationSelectors';

export const getCategories = (state: AppState) => state.contract.categories;
export const getPortfolioFilters = (state: AppState) => state.contract.portfolioFilters;
export const getContracts = (state: AppState) => state.contract.contracts;
export const getMyContracts = (state: AppState) => state.contract.myContracts;
export const getContractsWithRag = (state: AppState) => state.contract.contractsWithRag;
export const getContractPriorityDashboard = (state: AppState) => state.contract.priorityDashboard;
export const getDepartments = (state: AppState) => state.contract.departments;
export const getLocations = (state: AppState) => state.contract.locations;
export const getContractPrioritiesFilters = (state: AppState) =>
    state.contract.contractPrioritiesFilters;
export const getBenefitPrioritiesFilters = (state: AppState) =>
    state.contract.benefitPrioritiesFilters;
export const getContractFiles = (state: AppState) => state.contract.contractFiles;
export const getFetchingFiltersStatus = (state: AppState) => state.contract.isFetching;
export const getFetchingContractsStatus = (state: AppState) =>
    state.contract.isFetchingContracts || state.contract.isFetchingMyContracts;
export const getFilteringContractsStatus = (state: AppState) => state.contract.isFiltered;
export const getContractRoles = (state: AppState) => state.contract.contractRoles;
export const getContractPriorities = (state: AppState) => state.contract.priorities;
export const getDateRangeContractDashboard = (state: AppState) =>
    state.contract.contractFilterDateRange;
export const getDateRangeContractPrioritiesDashboard = (state: AppState) =>
    state.contract.contractPrioritiesFilterDateRange;
export const getContractNotes = (state: AppState) => state.contract.contractNotes;
export const getContractNoteFormVisibility = (state: AppState) => state.contract.formVisible;

export const getContract = createSelector(getContracts, (contracts) => contracts[0]);

export const getName = (name: string, archived: boolean) =>
    `${name}${archived ? ' (Archived)' : ''}`;

export const getAllCategories = createSelector(getCategories, (categories) =>
    categories.map(({ name, ...category }) => ({
        ...category,
        name: getName(name, category.archived),
    })),
);

export const getAllPortfolioFilters = createSelector(getPortfolioFilters, (portfolioFilters) =>
    portfolioFilters.map(({ name, ...portfolioFilter }) => ({
        ...portfolioFilter,
        name: getName(name, portfolioFilter.archived),
    })),
);

export const getAllDepartments = createSelector(getDepartments, (departments) =>
    departments.map(({ name, ...department }) => ({
        ...department,
        name: getName(name, department.archived),
    })),
);

export const getAllLocations = createSelector(getLocations, (locations) =>
    locations.map(({ name, ...location }) => ({
        ...location,
        name: getName(name, location.archived),
    })),
);

export const getActiveCategories = createSelector(getCategories, filterArchived);

export const getActiveDepartments = createSelector(getDepartments, filterArchived);

export const getActiveLocations = createSelector(getLocations, filterArchived);

export const getTitles = (data: Contract[]) => data && data.map((element) => element.title);

export const getContractByRouteId = createSelector(
    getContracts,
    extractRouteId,
    (data: Contract[], id: number) => data.find((element) => element.id === id),
);

export const getContractTitles = createSelector(getContracts, getTitles);
export const getContractByBenefitRouteId = createSelector(
    getContracts,
    getBenefitByRouteId,
    (contracts, benefit) => benefit && contracts.find(({ id }) => id === benefit.contract),
);

export const getMyContractByMyBenefitRouteId = createSelector(
    getMyContracts,
    getMyBenefitByRouteId,
    (contracts, benefit) => benefit && contracts.find(({ id }) => id === benefit.contract),
);

export const getActiveAndSelectedDepartments = createSelector(
    getAllDepartments,
    getContractByRouteId,
    (departments, contract) =>
        contract
            ? departments.filter(({ archived, id }) => !archived || id === contract.department)
            : [],
);

export const getActiveAndSelectedLocations = createSelector(
    getAllLocations,
    getContractByRouteId,
    (locations, contract) =>
        contract
            ? locations.filter(({ archived, id }) => !archived || id === contract.location)
            : [],
);

export const getActiveAndSelectedCategories = createSelector(
    getAllCategories,
    getContractByRouteId,
    (categories, contract) =>
        contract
            ? categories.filter(({ archived, id }) => !archived || id === contract.category)
            : [],
);

export const getActiveAndSelectedPortfolioFilters = createSelector(
    getAllPortfolioFilters,
    getContractByRouteId,
    (portfolioFilters, contract) =>
        contract
            ? portfolioFilters.filter(
                  ({ archived, id }) => !archived || id === contract.portfolioFilter,
              )
            : [],
);

export const getMyContractByBenefitRouteId = createSelector(
    getMyContracts,
    getBenefitByRouteId,
    (contracts, benefit) => benefit && contracts.find(({ id }) => id === benefit.contract),
);

export const getContractManagers = createSelector(
    getAllEmployees,
    getContractRoles,
    (employees, roles) => {
        if (employees.length === 0) {
            return [];
        }

        const managers = roles.filter(({ role }) => role === ContractRole.CONTRACT_MANAGER);

        return managers.map((manager) => ({
            ...employees.find(({ user: { id } }) => manager.user === id)!,
            id: manager.id,
        }));
    },
);

export const getContractSuppliers = createSelector(
    getExecutives,
    getContractRoles,
    (organisations, roles) => {
        if (organisations.length === 0 || roles.length === 0) {
            return [];
        }

        const suppliers = roles.filter(
            ({ role, organisation }) => role === ContractRole.SUPPLIER && organisation,
        );

        return suppliers.map((supplier) => ({
            ...supplier,
            name: organisations.find(({ id }) => supplier.organisation === id)!.name,
        }));
    },
);

const getContractManagerStatus = (
    roles: ContractRoleData[],
    userId: number,
    contracts: Contract[],
) => {
    if (roles.length === 0 || contracts.length === 0) {
        return false;
    }
    const role = roles.find(
        ({ user, role, contract }) =>
            user === userId &&
            role === ContractRole.CONTRACT_MANAGER &&
            contract === contracts[0].id,
    );

    if (role) {
        return true;
    }
    return false;
};

export const isContractManager = createSelector(
    getContractRoles,
    getUserId,
    getContracts,
    getContractManagerStatus,
);
