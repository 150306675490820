import * as React from 'react';
import styled from 'styled-components';
import { List, Modal } from 'antd';
import { startCase, camelCase } from 'lodash';

import { ListItem, PaddedSection } from 'common/components';
import {
    formatProfileForDetails,
    formatRAGStatus,
    formatRouteToMethod,
} from 'common/helpers/utils';
import { colors, fontWeights } from 'theme/variables';
import { PrioritiesWrapper } from 'features/benefit/components/PrioritiesWrapper';
import { PriorityWrapper } from 'features/benefit/components/PriorityWrapper';
import { PriorityName } from 'features/benefit/components/PriorityName';
import { Antd3Icon } from 'common/components/deprecated/antd3';

import { ContractDetailsProps } from '../containers/ContractDetailsContainer';
import { Detail, OrganisationRole } from '../models/contractModels';
import { FileList } from 'features/check/components/FileList';
import { useSelector } from 'react-redux';
import { getContractFiles } from '../selectors/contractSelectors';

const ListWrapper = styled.div`
    width: 100%;
    background-color: ${colors.primaryWhite};
    margin-bottom: 1.5rem;
    .ant-list-header {
        background-color: ${colors.primary};
        color: ${colors.primaryWhite};
        font-weight: ${fontWeights.bold};
    }
`;

export const ContractDetails: React.FunctionComponent<ContractDetailsProps> = (props) => {
    const { contract, contractManagers, contractSuppliers, priorities, contractId } = props;

    if (!contract) {
        return null;
    }
    const formatSuppliers = (role: OrganisationRole[]) =>
        role.length > 0 && role.map(({ name }) => (name ? name : '')).join(', ');

    const contractFiles = useSelector(getContractFiles);

    const details = [
        { name: 'Project Title', value: contract.title },
        { name: 'Project Reference Number', value: contract.referenceNumber },
        { name: 'Project Description', value: contract.description },
        { name: 'Project Type', value: startCase(camelCase(contract.contractType)) },
        { name: 'Project Category', value: contract.categoryName },
        { name: 'Portfolio', value: contract.portfolioFilterName || 'None' },
        { name: 'Department', value: contract.departmentName },
        { name: 'Project Location', value: contract.locationName || 'None' },
        { name: 'Budget Source', value: startCase(camelCase(contract.budget)) || 'Not set' },
        { name: 'Route to Market', value: formatRouteToMethod(contract) },
        { name: 'Framework Project', value: contract.frameworkContractName },
        { name: 'Project Value', value: contract.value },
        { name: 'Project Priorities', priorities },
        { name: 'Project Start Date', value: contract.startDate },
        { name: 'Project Award Date', value: contract?.awardedOn },
        { name: 'Project Completion Date', value: contract.endDate },
        {
            name: 'Project Managers',
            value: formatProfileForDetails(contractManagers),
        },
        {
            name: 'Supplier',
            value: contractSuppliers.length === 0 ? 'None' : formatSuppliers(contractSuppliers),
        },

        { name: 'Target Benefit Value', value: contract.targetBenefitValue },
        {
            name: 'Benefit Value',
            value: contract.benefitValueRag || 'None',
            icon: formatRAGStatus(contract.benefitValueRag),
        },
        { name: 'Target Benefit Points', value: contract.targetBenefitPoints },
        {
            name: 'Benefit Points',
            value: contract.benefitPointsRag || 'None',
            icon: formatRAGStatus(contract.benefitPointsRag),
        },
        { name: 'Target Benefit Emission Savings', value: contract.targetBenefitEmissionSavings },
        {
            name: 'Benefit Emission Savings',
            value: contract.benefitEmissionSavingsRag || 'None',
            icon: formatRAGStatus(contract.benefitEmissionSavingsRag),
        },
        { name: 'Target Benefit Delivery Number', value: contract.targetBenefitDeliveryNumber },
        {
            name: 'Benefit Delivered Number',
            value: contract.benefitDeliveredNumberRag || 'None',
            icon: formatRAGStatus(contract.benefitDeliveredNumberRag),
        },
        { name: 'Project Status', value: startCase(camelCase(contract.status)) },
        { name: 'Published', value: contract.published ? 'Yes' : 'No' },
    ];

    const deleteContract = () =>
        Modal.confirm({
            title: 'If you delete this project, you will also delete any benefits associated with it. Are you sure you want to continue?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                props.deleteContractRequest(contract.id);
            },
        });

    return (
        <PaddedSection>
            <ListWrapper>
                <List
                    header={
                        <ListItem
                            text="Project Details"
                            buttons={[
                                {
                                    name: 'Remove',
                                    icon: 'delete',
                                    type: 'danger',
                                    onClick: deleteContract,
                                },
                            ]}
                            header="true"
                        />
                    }
                    bordered
                    dataSource={details}
                    renderItem={({ name = '', value, priorities, icon }: Detail) =>
                        priorities ? (
                            <PrioritiesWrapper>
                                {name}:
                                {priorities.map((priority) => (
                                    <PriorityWrapper key={priority.id}>
                                        <PriorityName>{priority.priorityName}:</PriorityName>
                                        <span>{priority.value}</span>
                                    </PriorityWrapper>
                                ))}
                            </PrioritiesWrapper>
                        ) : icon ? (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <Antd3Icon
                                            type="minus-circle"
                                            theme="filled"
                                            style={{ color: icon, fontSize: '20px' }}
                                        />
                                    }
                                    title={`${name}: ${value}`}
                                />
                            </List.Item>
                        ) : (
                            <List.Item>
                                {name}: {value}
                            </List.Item>
                        )
                    }
                />
            </ListWrapper>
            <ListWrapper>
                <List header={<ListItem text="Project Files" header="true" />} bordered>
                    <FileList emptyText="No files attached to the Project" files={contractFiles} />
                </List>
            </ListWrapper>
        </PaddedSection>
    );
};
