import * as React from 'react';
import { Button, Radio } from 'antd';
import styled, { keyframes } from 'styled-components';
import { Profile } from 'features/user/models/userModel';
import { breakpoints } from 'theme/variables';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { Input, PhoneInput, ResetButton } from 'common/components/deprecated/form';
import { CenteringFormItem } from 'common/components/styled';
import { PaddedSection } from 'common/components';
import { Organisation } from 'features/organisation/models/organisationModels';
import { delay } from 'lodash';

const SubmitButtonsWrapper = styled(CenteringFormItem)`
    button {
        margin-right: 20px;
    }
    ${breakpoints.small} {
        button {
            margin: 0 20px;
        }
    }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const RadioMessage = styled.div<{ color?: string }>`
    color: ${({ color }) => color || 'red'};
    transform: translateY(-10px);
    transition: 'all 0.3s ease-out';
    &.fade-in {
        animation: ${fadeIn} 0.3s ease-out forwards;
    }
    &.fade-out {
        animation: ${fadeOut} 0.3s ease-out forwards;
    }
`;

export interface SubmitButtonsProps {
    showInviteButton?: boolean;
    showSaveButton?: boolean;
    isFetching?: boolean;
}

interface SupplierUserFormProps extends SubmitButtonsProps {
    profile: Profile;
    isFetching?: boolean;
    handleRoleChange: (isAdmin: boolean) => void;
    organisation: Organisation;
}

type Props = Antd3FormProps & SupplierUserFormProps;

export const SubmitButtons: React.FunctionComponent<SubmitButtonsProps> = ({
    showInviteButton,
    showSaveButton,
    isFetching,
}) => (
    <SubmitButtonsWrapper>
        {showInviteButton && (
            <Button type="primary" htmlType="submit" disabled={showSaveButton}>
                Invite
            </Button>
        )}
        {showSaveButton && (
            <Button
                disabled={!showSaveButton}
                type="primary"
                htmlType="submit"
                loading={isFetching}
            >
                Save
            </Button>
        )}
    </SubmitButtonsWrapper>
);

export const SupplierUserForm: React.FunctionComponent<Props> = ({
    profile,
    role,
    handleRoleChange: handleRoleChange,
    ...props
}) => {
    const { form, organisation } = props;
    const {
        telephone,
        user: { firstName, lastName },
    } = profile;

    const availableAdminRoles = organisation.availableAdministratorRoles || 0;
    const [radioMessageState, setRadioMessageState] = React.useState<{
        message: string;
        isVisible: boolean;
    }>({
        message: '',
        isVisible: false,
    });

    const onRadioChange = (e: any) => {
        const role = e.target.value;
        handleRoleChange(role);
        if (role) {
            const message = availableAdminRoles
                ? `${availableAdminRoles - 1}/${availableAdminRoles} admin roles left`
                : 'No admin roles left';
            setRadioMessageState({ message, isVisible: true });
        } else {
            setRadioMessageState((prevState) => ({
                ...prevState,
                isVisible: false,
            }));
            delay(() => setRadioMessageState({ message: '', isVisible: false }), 300);
        }
    };

    return (
        organisation && (
            <>
                <PaddedSection>
                    <ResetButton form={form} />
                    <Input form={form} id="firstName" label="First name" initialValue={firstName} />
                    <Input form={form} id="lastName" label="Last Name" initialValue={lastName} />
                    <PhoneInput
                        form={form}
                        id="telephone"
                        placeholder="Telephone Number"
                        initialValue={telephone}
                    />
                    <Antd3Form.Item>
                        <Radio.Group
                            id="assign"
                            name="assign"
                            defaultValue={false}
                            onChange={onRadioChange}
                        >
                            <Radio value={true}>Admin</Radio>
                            <Radio value={false}>User</Radio>
                        </Radio.Group>
                        <RadioMessage
                            color={!availableAdminRoles ? 'red' : ''}
                            className={!radioMessageState.isVisible ? 'fade-out' : 'fade-in'}
                        >
                            {radioMessageState.message}
                        </RadioMessage>
                    </Antd3Form.Item>
                    <SubmitButtons {...props} />
                </PaddedSection>
            </>
        )
    );
};
