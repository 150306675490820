import { useEffect } from 'react';
import { Button, Divider, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useForm } from 'antd/lib/form/Form';
import { CloudDownloadOutlined } from '@ant-design/icons';

import { notificationText, showNotification } from 'common/helpers/notifications';
import { Checkbox, Form, FullPageSpinner, PaddedSection } from 'common/components';
import { Contract } from 'features/contract/models/contractModels';
import { Benefit } from 'features/benefit/models/benefitModels';
import { getUser } from 'features/user/selectors/userSelectors';
import { BenefitDetails } from 'common/components/BenefitDetails';
import { isDebug } from 'config/env';
import { scorePath } from 'common/helpers/utils';
import { UploadMultiFiles } from 'common/components/deprecated/form';
import { FileList } from 'features/check/components/FileList';
import { CustomEmpty } from 'common/components/CustomEmpty';
import {
    formatQuestionnaireAnswerForApi,
    getQuestionnaireAnswerInitialFormValues,
} from 'features/benefit/benefitHelpers';
import { QuestionnaireAnswers } from 'features/benefit/components/Questionnaire/QuestionnaireAnswers';
import { QuestionnaireAnswer } from 'features/benefit/models/questionnaireModels';
import { Questionnaire } from 'features/benefit/components/Questionnaire/Questionnaire';
import { isFormValid } from 'common/helpers/formHelpers';

import { Score } from '../models/uploadModels';
import * as uploadSelectors from '../selectors/uploadSelectors';
import { clearUploadData } from '../actions/uploadActions';
import * as scoreActions from '../actions/scoreActions';
import { AssignedContractManagers } from './AssignedContractManagers';
import { ApprovalStatus } from './ApprovalStatus';
import { GdprTerms } from './GdprTerms';

const ButtonsWrapper = styled(Space)`
    width: 100%;
    justify-content: flex-end;
`;

const FilesWrapper = styled.div`
    width: 100%;
    padding: 1.5rem;
`;

type Props = {
    contractId: number;
    contract: Contract;
    benefitId: number;
    benefit: Benefit;
};

type BenefitScoreFormValues = {
    agreedToGdpr?: boolean;
    questionnaireAnswer?: QuestionnaireAnswer[];
};

const generateInitialFormValues = (benefit: Benefit, score?: Score): BenefitScoreFormValues => ({
    agreedToGdpr: score?.agreedToGdpr,
    questionnaireAnswer: getQuestionnaireAnswerInitialFormValues(
        benefit.scoreQuestionnaire,
        score?.questionnaireAnswer,
    ),
});

export const BenefitScore = ({ contractId, contract, benefitId, benefit }: Props) => {
    const [form] = useForm<BenefitScoreFormValues>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearUploadData());
        form.resetFields();
        dispatch(
            scoreActions.getMyScoreRequest({
                organisationId: contract.organisation,
                benefitId,
                contractId,
            }),
        );
    }, [benefit]);

    const score = useSelector(uploadSelectors.getMyScore);
    const user = useSelector(getUser);
    const fetchingStatus = useSelector(uploadSelectors.getFetchingStatus);
    const contractManagerDetails = useSelector(uploadSelectors.getContractManagerDetails);
    const isUploading = useSelector(uploadSelectors.getUploadingStatus);

    useEffect(() => {
        form.resetFields();
    }, [score]);

    const handleSubmit = (submitAction: any, selfAssessment?: boolean) => () => {
        if (!isFormValid(form)) {
            showNotification({ text: notificationText.Error });

            return;
        }

        const formValues = form.getFieldsValue();

        dispatch(
            submitAction({
                benefitId,
                contractId: contract.id,
                scoreId: score?.id,
                userId: user.id,
                organisationId: contract.organisation,
                ...formValues,
                questionnaireAnswer: formatQuestionnaireAnswerForApi(
                    benefit.scoreQuestionnaire,
                    formValues.questionnaireAnswer,
                ),
                selfAssessment,
            }),
        );
    };

    const deleteFile = (fileId: number): void => {
        if (score) {
            dispatch(
                scoreActions.deleteScoreFileRequest({
                    benefitId,
                    contractId: contract && contract.id,
                    scoreId: score.id,
                    organisationId: contract.organisation,
                    fileId,
                }),
            );
        }
    };

    const isEditBlocked =
        score?.approved &&
        ![score?.author, score?.approvedBy].every(
            (fullName) => fullName === `${user.firstName} ${user.lastName}`,
        );

    return (
        <>
            <PaddedSection grayBg bottomBorder>
                <BenefitDetails
                    benefit={benefit}
                    contract={contract}
                    showFinalDeliveryDate={false}
                />
                <AssignedContractManagers contractManagerDetails={contractManagerDetails} />
            </PaddedSection>
            {fetchingStatus === 'idle' ? (
                <FullPageSpinner />
            ) : (
                <PaddedSection>
                    <Form
                        form={form}
                        initialValues={generateInitialFormValues(benefit, score)}
                        hideSubmitButton
                    >
                        <ApprovalStatus
                            approved={score?.approved}
                            declined={score?.declined}
                            approvedAt={score?.approvedAt}
                            approvedBy={score?.approvedBy}
                            declinedBy={score?.declinedBy}
                            declineMessage={score?.declineMessage}
                            bottomMargin
                        />
                        {isEditBlocked ? (
                            <QuestionnaireAnswers
                                questions={score.scoreQuestionnaire}
                                answers={score.questionnaireAnswer}
                            />
                        ) : (
                            <>
                                {benefit.scoreQuestionnaire && (
                                    <Questionnaire questions={benefit.scoreQuestionnaire} />
                                )}
                                <Checkbox name="agreedToGdpr">
                                    <GdprTerms />
                                </Checkbox>
                                <ButtonsWrapper>
                                    {benefit.scoreFileTemplate && (
                                        <Button
                                            icon={<CloudDownloadOutlined />}
                                            href={
                                                isDebug()
                                                    ? benefit.scoreFileTemplate
                                                    : `https://${benefit.scoreFileTemplate}`
                                            }
                                            target="blank"
                                            rel="noopener noreferrer"
                                        >
                                            Download Feedback Template
                                        </Button>
                                    )}
                                    <Button
                                        type="primary"
                                        onClick={handleSubmit(
                                            score
                                                ? scoreActions.updateScoreRequest
                                                : scoreActions.createScoreRequest,
                                        )}
                                        size="large"
                                        disabled={isUploading}
                                    >
                                        {`${score ? 'Update' : 'Create'} Feedback`}
                                    </Button>
                                    {benefit.selfAssessmentAllowed && (
                                        <Button
                                            type="primary"
                                            onClick={handleSubmit(
                                                score
                                                    ? scoreActions.updateScoreRequest
                                                    : scoreActions.createScoreRequest,
                                                true,
                                            )}
                                            size="large"
                                            disabled={isUploading || !score}
                                            loading={isUploading}
                                        >
                                            {`${score ? 'Approve' : 'Create & Approve'} Feedback`}
                                        </Button>
                                    )}
                                    <Button
                                        type="primary"
                                        onClick={handleSubmit(
                                            scoreActions.submitScoreForApprovalRequest,
                                        )}
                                        size="large"
                                        disabled={
                                            isUploading ||
                                            score?.approved ||
                                            !score?.questionnaireAnswer
                                        }
                                    >
                                        Submit for Approval
                                    </Button>
                                </ButtonsWrapper>
                            </>
                        )}
                    </Form>
                    <Divider />
                    <FilesWrapper>
                        {score && !isEditBlocked ? (
                            <UploadMultiFiles
                                uploadUrl={`${scorePath({
                                    organisationId: contract.organisation,
                                    contractId: benefit.contract,
                                    benefitId: benefit.id,
                                })}${score.id}/files/`}
                                successAction={scoreActions.uploadScoreFileSuccess}
                                failureAction={scoreActions.uploadScoreFileFailure}
                            />
                        ) : (
                            !score?.approved && (
                                <CustomEmpty description="Please create Feedback prior to file upload." />
                            )
                        )}
                        {Boolean(score?.files?.length) && (
                            <FileList
                                loading={isUploading}
                                files={score?.files}
                                onDelete={!score?.approved && deleteFile}
                            />
                        )}
                    </FilesWrapper>
                </PaddedSection>
            )}
        </>
    );
};
