import { inject, injectable } from 'inversify';

import { HttpService } from 'common/services/HttpService';
import { SERVICE } from 'config/identifiers';
import { config, route } from 'config/config';

import {
    User,
    UpdateUserData,
    CreateAccountCredentials,
    ProfileRequest,
    Profile,
    UserOrganisation,
    ChangePasswordRequest,
    ResetPasswordRequest,
    ChangeEmailRequest,
    ProfileResponse,
    ChangeEmailResponse,
    Subscription,
    InvoiceData,
    SubscriptionPurchaseData,
    Invoice,
} from '../models/userModel';

@injectable()
export class UserService {
    constructor(@inject(SERVICE.Http) private readonly http: HttpService) {}

    public archiveEmployee(id: number, archived: boolean): Promise<void> {
        return this.http.PATCH(`${config.routeConfig.employees}${id}/`, { archived });
    }

    public deleteEmployee(id: number): Promise<void> {
        return this.http.DELETE(`${config.routeConfig.employees}${id}/`);
    }

    public confirmNewPassword(token: string): Promise<string> {
        return Promise.resolve(token);
    }

    public async createAccount(data: CreateAccountCredentials): Promise<void> {
        const account = await this.http.GET<any>(
            `${config.routeConfig.invitations}${localStorage.getItem('token')}/`,
        );

        return this.http.PATCH(`${config.routeConfig.users}${account.id}/`, data);
    }

    public editEmployee(employee: ProfileRequest): Promise<User> {
        return this.http.PATCH(`${config.routeConfig.employees}${employee.id}/`, employee);
    }

    public getEmployees(): Promise<Profile[]> {
        return this.http.GET<Profile[]>(config.routeConfig.employees);
    }

    public getUser(): Promise<ProfileResponse> {
        return this.http.GET<ProfileResponse>(config.routeConfig.me);
    }

    public getUserOrganisations(): Promise<UserOrganisation[]> {
        return this.http.GET<UserOrganisation[]>(config.routeConfig.availableProfiles);
    }

    public getSubscriptionTiers(): Promise<Subscription[]> {
        return this.http.GET<Subscription[]>(config.routeConfig.subscriptionTiers);
    }

    public getSubscriptions(): Promise<Subscription[]> {
        return this.http.GET<Subscription[]>(config.routeConfig.organisationSubscriptions);
    }

    public getSubscriptionInvoices(id: number): Promise<Invoice[]> {
        return this.http.GET<Invoice[]>(
            `${config.routeConfig.organisationSubscriptions}${id}/invoices/`,
        );
    }
    public cancelSubscription(id: number): Promise<Subscription> {
        return this.http.GET<Subscription>(
            `${config.routeConfig.organisationSubscriptions}${id}/cancel/`,
        );
    }
    public getInvoiceData(): Promise<InvoiceData> {
        return this.http.GET<InvoiceData>(config.routeConfig.xeroCustomer);
    }

    public saveInvoiceData(data: InvoiceData): Promise<InvoiceData> {
        return this.http.POST<InvoiceData>(config.routeConfig.xeroCustomer, data);
    }

    public updateInvoiceData(data: InvoiceData): Promise<InvoiceData> {
        return this.http.PATCH<InvoiceData>(config.routeConfig.xeroCustomer, data);
    }

    public requestSubscriptionPurchase(
        data: SubscriptionPurchaseData,
    ): Promise<SubscriptionPurchaseData> {
        return this.http.POST<SubscriptionPurchaseData>(
            config.routeConfig.organisationSubscriptions,
            data,
        );
    }

    public updateUserData(data: UpdateUserData): Promise<User> {
        return this.http.PATCH<User>(config.routeConfig.me, data);
    }

    public changeUserPassword(id: number, data: ChangePasswordRequest): Promise<string> {
        return this.http.PATCH<string>(
            `${config.routeConfig.users}${id}/${route.changePassword}/`,
            data,
        );
    }

    public sendResetPassword(email: string): Promise<string> {
        return this.http.POST(`${config.routeConfig.requestPasswordReset}`, { email });
    }

    public verifyResetPasswordToken(token: string): Promise<string> {
        return this.http.POST(`${config.routeConfig.verifyResetPasswordToken}`, { token });
    }

    public resetPasswordChange({ token, ...resetPassword }: ResetPasswordRequest): Promise<string> {
        return this.http.PATCH(`${config.routeConfig.resetPasswordChange}`, resetPassword, token);
    }
    public updateUserEmail({
        id,
        email,
        password,
    }: ChangeEmailRequest): Promise<ChangeEmailResponse> {
        return this.http.PATCH<ChangeEmailResponse>(
            `${config.routeConfig.users}${id}/${route.changeEmail}/`,
            {
                email,
                password,
                passwordConfirmation: password,
            },
        );
    }
}
