import { switchMap, pluck, debounceTime } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';

import { AppEpic } from 'common/epics/appEpic';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { AppState } from 'common/appState';
import { openModal } from 'common/helpers/modal';
import {
    DownloadContractsFileNames,
    DownloadBenefitsFileNames,
    MimeFileTypes,
    saveBlob,
} from 'common/helpers/utils';

import * as actions from '../actions/downloadActions';
import { DownloadService } from '../services/downloadService';
import { DownloadRequest, InvoiceDownloadRequest } from '../models/downloadModel';

export const downloadEpicFactory = (downloadService: DownloadService): AppEpic => {
    const getBenefitsCsvEpic: AppEpic = (action$, store) =>
        action$.pipe(
            ofType(actions.GET_BENEFITS_CSV_REQUESTED),
            debounceTime(1200),
            pluck('payload'),
            switchMap((request: DownloadRequest) => {
                const state: AppState = store.getState();
                const filters = state.pagination.filters.find(
                    ({ paginatedView }) => paginatedView === PaginationView.DownloadBenefits,
                );

                return downloadService
                    .exportBenefits({ ...request, filters: filters && filters.values })
                    .then((blob) => {
                        if (blob.size > 0) {
                            const fileName =
                                blob.type === MimeFileTypes.ODS
                                    ? DownloadBenefitsFileNames.ODS
                                    : DownloadBenefitsFileNames.XLSX;

                            downloadService.saveBlob({ fileName, blob });
                            return actions.getBenefitsCSVSuccess();
                        }
                        openModal({
                            content:
                                "You've requested a Benefits report with more than 100 records. We'll prepare it and send you an email once it's ready.",
                            title: "We'll email you once your report is ready.",
                            modalType: 'info',
                        });
                        return actions.delayedCSVDownload();
                    })
                    .catch(actions.getBenefitsCSVFailure);
            }),
        );

    const downloadInvoicePdfEpic: AppEpic = (action$, store) =>
        action$.pipe(
            ofType(actions.DOWNLOAD_INVOICE_PDF_REQUESTED),
            debounceTime(1200),
            pluck('payload'),
            switchMap((request: InvoiceDownloadRequest) =>
                downloadService
                    .exportInvoicePdf(request)
                    .then((response) => response.blob())
                    .then((blob) => {
                        const fileName = 'Invoice.pdf';
                        const invoiceUrl = URL.createObjectURL(blob);
                        downloadService.saveBlob({ fileName, blob });
                        return actions.downloadInvoicePdfSuccess(invoiceUrl);
                    })
                    .catch(actions.downloadInvoicePdfFailure),
            ),
        );

    const getContractsCsvEpic: AppEpic = (action$, store) =>
        action$.pipe(
            ofType(actions.GET_CONTRACTS_CSV_REQUESTED),
            debounceTime(1200),
            pluck('payload'),
            switchMap((request: DownloadRequest) => {
                const state: AppState = store.getState();
                const filters = state.pagination.filters.find(
                    ({ paginatedView }) => paginatedView === PaginationView.DownloadContracts,
                );

                return downloadService
                    .exportContracts({ ...request, filters: filters && filters.values })
                    .then((blob) => {
                        if (blob.size > 0) {
                            const fileName =
                                blob.type === MimeFileTypes.ODS
                                    ? DownloadContractsFileNames.ODS
                                    : DownloadContractsFileNames.XLSX;

                            saveBlob({ fileName, blob });
                            return actions.getContractsCSVSuccess();
                        }
                        openModal({
                            content:
                                "You've requested a Contracts report with more than 100 records. We'll prepare it and send you an email once it's ready.",
                            title: "We'll email you once your report is ready.",
                            modalType: 'info',
                        });
                        return actions.delayedCSVDownload();
                    })
                    .catch(actions.getContractsCSVFailure);
            }),
        );

    return combineEpics(getBenefitsCsvEpic, getContractsCsvEpic, downloadInvoicePdfEpic);
};
