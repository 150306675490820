import { Select as AntdSelect } from 'antd';
import styled from 'styled-components';

import { fontSizes } from 'theme/variables';
import { close } from 'common/svg';
import { IdValueNameObject } from 'common/models/defaultsModel';

import { filterOption } from './Select';
import { Antd3Form, Antd3InputProps } from '../antd3';

interface Props extends Antd3InputProps {
    options: IdValueNameObject[];
    initialValue?: string[];
    onChange?(ids: number[]): void;
}

const Select = styled(AntdSelect)`
    && .ant-select-selection__choice__remove {
        height: inherit;
        padding: 11px 0;
    }
    && .ant-select-selection__choice__remove:before {
        content: '';
        background-image: url(${close});
        width: ${fontSizes.extraSmall};
        height: ${fontSizes.extraSmall};
    }
`;

export const MultiSelect = ({
    options,
    form,
    id,
    label,
    placeholder,
    requiredMessage,
    required = false,
    initialValue,
    onChange,
}: Props) => (
    <Antd3Form.Item label={label || placeholder}>
        {form.getFieldDecorator(id, {
            initialValue,
            rules: [
                { required, message: requiredMessage || `${label || placeholder} is required` },
            ],
        })(
            <Select
                filterOption={filterOption}
                mode="multiple"
                placeholder={placeholder || label}
                size="large"
                onChange={onChange}
            >
                {options.map((option) => (
                    <AntdSelect.Option key={option.id} value={option.id}>
                        {option?.name || option?.value}
                    </AntdSelect.Option>
                ))}
            </Select>,
        )}
    </Antd3Form.Item>
);
