import * as React from 'react';

import { ModalInput } from 'common/components/deprecated/form';
import { Antd3FormProps, Antd3Icon } from 'common/components/deprecated/antd3';
import { PaddedSection } from 'common/components';
import styled from 'styled-components';

interface InviteExecutiveUserProps {
    handleChange: (e: React.FormEvent) => void;
    organisationName: string;
}

export const WarningMessageWrapper = styled.p`
    width: 100%;
    padding-inline: calc((100% - 97%) / 2);
    align-items: baseline;
    color: #faad14;
    cursor: pointer;
    font-size: medium;
    line-height: 1.5;
`;

export class InviteExecutiveUserForm extends React.Component<
    InviteExecutiveUserProps & Antd3FormProps
> {
    public render(): JSX.Element {
        const { handleChange, form } = this.props;

        const warningMessages = [
            <>
                Please double check that you are inviting this user to the correct Supplier or
                Delivery Partner organisation.
            </>,
            <>
                The organisation you are inviting them to is{' '}
                <strong>{this.props.organisationName}</strong>.
            </>,
        ];

        return (
            <PaddedSection>
                {warningMessages.map((message) => (
                    <WarningMessageWrapper key={message}>
                        {' '}
                        <Antd3Icon type="exclamation-circle" style={{ marginRight: '5px' }} />
                        {message}
                    </WarningMessageWrapper>
                ))}

                <ModalInput
                    form={form}
                    id="First Name"
                    name="first_name"
                    placeholder="First Name"
                    onChange={handleChange}
                    required
                />
                <ModalInput
                    form={form}
                    id="last_name"
                    name="last_name"
                    placeholder="Last Name"
                    onChange={handleChange}
                    required
                />
                <ModalInput
                    form={form}
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email Address"
                    onChange={handleChange}
                    required
                />
                <ModalInput
                    form={form}
                    id="telephone"
                    name="telephone"
                    placeholder="Telephone Number"
                    onChange={handleChange}
                    required={false}
                />
            </PaddedSection>
        );
    }
}
