import { SmallTabs } from 'common/components/styled';
import { Tabs } from 'antd';
import { Props } from '../containers/SubscriptionContainer';
import { Subscription, UserPaths } from '../models/userModel';
import { InvoiceForm } from './InvoiceForm';
import { InvoiceList } from './InvoiceList';
import { SubscriptionCards } from './SubscriptionCards';
import { SubscriptionUserInfo } from './SubscriptionUserInfo';
import { SubscriptionsHistory } from './SubscriptionsHistory';
import { UpstreamDetailsContainer } from 'features/organisation/containers/UpstreamDetailsContainer';

const TabPaneTitles = {
    SubscriptionDetails: 'Subscription Details',
    SubscriptionHistory: 'Subscription History',
    UpstreamClient: 'Upstream Client',
};

export const SubscriptionDetails = ({
    userSubscription,
    profile,
    isUserAdmin,
    isUserTenant,
}: Props) => {
    const isAdminAndHasActiveSubscription =
        userSubscription && userSubscription.status === 'ACTIVE' && isUserAdmin;

    return (
        <SmallTabs>
            <Tabs.TabPane
                key={TabPaneTitles.SubscriptionDetails}
                tab={TabPaneTitles.SubscriptionDetails}
            >
                <SubscriptionCards profile={profile} userSubscription={userSubscription} />

                {isAdminAndHasActiveSubscription && (
                    <>
                        <SubscriptionUserInfo
                            profile={profile}
                            userSubscription={userSubscription}
                            isUserAdmin={isUserAdmin}
                        />
                        <InvoiceList
                            userSubscription={userSubscription}
                            profile={profile}
                            isUserAdmin={isUserAdmin}
                        />
                    </>
                )}

                <InvoiceForm />
            </Tabs.TabPane>

            <Tabs.TabPane
                key={TabPaneTitles.SubscriptionHistory}
                tab={TabPaneTitles.SubscriptionHistory}
            >
                <SubscriptionsHistory />
            </Tabs.TabPane>

            {isUserAdmin && isUserTenant && (
                <Tabs.TabPane key={TabPaneTitles.UpstreamClient} tab={TabPaneTitles.UpstreamClient}>
                    <UpstreamDetailsContainer />
                </Tabs.TabPane>
            )}
        </SmallTabs>
    );
};
