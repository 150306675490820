import * as React from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { TableRowSelection } from 'antd/lib/table/interface';

import { breakpointsValues } from 'theme/variables';
import { defaultPagination } from 'config/variables';
import { history } from 'config/config';
import { ContractWithRAG } from 'features/contract/models/contractModels';
import { MainFormHeader } from 'common/components/styled';

import {
    PaginationResources,
    Row,
    Column,
    PaginationView,
    PaginationSuccessCallback,
    RequestParams,
} from '../models/paginationModel';
import {
    getPaginationState,
    getPaginationFetchingStatus,
    getPaginationFilters,
} from '../selectors/paginationSelectors';
import { paginationRequest, clearPagination } from '../actions/paginationActions';
import { PaginationFilters, PaginationStateObject } from '../reducers/paginationReducer';
import { ExpandableRow } from './ExpandableRow';

interface PaginatedTableProps extends PaginationSuccessCallback {
    data: Row[];
    columns: Array<Column<PaginationResources>>;
    view: PaginationView;
    emptyText?: string;
    header?: string;
    requestParams?: RequestParams;
    size?: 'default' | 'middle' | 'small';
    rowSelection?: TableRowSelection<PaginationResources>;
    hideHeader?: boolean;
    setFiltersFromQueryParams?(): void;
    isInvitationTable?: boolean;
    pageNumber?: number;
    withPaginationHistory?: boolean;
    refetchOnFiltersChange?: boolean;
    pathUrl?: string;
    topInterface?: JSX.Element;
    expandedRowRender?: (rowData: any) => JSX.Element;
}

interface PaginatedTableStateProps {
    pagination: PaginationStateObject;
    isFetching: boolean;
    filters: PaginationFilters[];
}

interface PaginatedTableDispatchProps {
    paginationRequest: typeof paginationRequest;
    clearPagination: typeof clearPagination;
}

type Props = PaginatedTableProps & PaginatedTableStateProps & PaginatedTableDispatchProps;

interface PaginatedTableState {
    windowWidth: number;
}

class PaginatedTableComponent extends React.Component<Props, PaginatedTableState> {
    public state: PaginatedTableState = {
        windowWidth: 500,
    };

    public componentDidMount(): void {
        this.props.setFiltersFromQueryParams && this.props.setFiltersFromQueryParams();
        this.changePage(this.props.pageNumber || defaultPagination.firstPage);
        this.updateWindowWidth();
        window.addEventListener('resize', this.updateWindowWidth);
    }

    public componentDidUpdate(prevProps: Props): void {
        const {
            pagination,
            paginationSuccessCallback,
            view,
            requestParams,
            withPaginationHistory,
            pageNumber,
            paginationRequest,
            refetchOnFiltersChange,
            filters,
        } = this.props;

        if (
            (refetchOnFiltersChange &&
                prevProps.filters &&
                filters &&
                filters[0]?.values &&
                prevProps.filters[0]?.values &&
                prevProps.filters !== filters) ||
            (withPaginationHistory &&
                pageNumber &&
                prevProps.pageNumber &&
                prevProps.pageNumber !== pageNumber)
        ) {
            paginationRequest({
                pagination: { ...pagination, current: pageNumber || 1 },
                paginationSuccessCallback,
                view,
                requestParams,
            });
        }
    }

    private changePage(page: number): void {
        const { pagination, paginationSuccessCallback, view, requestParams } = this.props;

        this.props.paginationRequest({
            pagination: { ...pagination, current: page },
            paginationSuccessCallback,
            view,
            requestParams,
        });

        this.props.withPaginationHistory &&
            history.push({
                pathname: `${this.props.pathUrl}/${page}`,
            });
    }

    private handleChange = ({ current }: PaginationStateObject) => this.changePage(current);

    public componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowWidth);
    };

    private updateWindowWidth = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    public render(): JSX.Element {
        const {
            data,
            columns,
            header,
            pagination,
            isFetching,
            size,
            rowSelection,
            hideHeader,
            emptyText = 'No data found',
            expandedRowRender,
        } = this.props;

        const isMobile = this.state.windowWidth < breakpointsValues.medium;
        const lastColumn = columns[columns.length - 1];
        const mobileColumns =
            lastColumn.key?.includes('id') && columns.length > 1
                ? [columns[0], lastColumn]
                : [columns[0]];
        const expandableRowsNumber = columns.length - mobileColumns.length;
        const displayExpandableRow = isMobile && expandableRowsNumber;
        const expandedRowRenderer = (rowData: PaginationResources) => {
            if (typeof expandedRowRender === 'function') {
                return expandedRowRender(rowData);
            } else {
                return <ExpandableRow rowData={rowData as ContractWithRAG} columns={columns} />;
            }
        };

        if (!rowSelection) {
            return (
                <>
                    {header && <MainFormHeader>{header}</MainFormHeader>}
                    {this.props.topInterface}
                    <Table
                        dataSource={data}
                        columns={columns}
                        rowKey={({ id }) => id.toString()}
                        locale={{ emptyText }}
                        pagination={{
                            ...pagination,
                            showSizeChanger: false,
                        }}
                        loading={isFetching}
                        onChange={this.handleChange}
                        size={size}
                        showHeader={!hideHeader}
                        scroll={{ x: 300 }}
                        expandedRowRender={displayExpandableRow ? expandedRowRenderer : undefined}
                    />
                </>
            );
        }

        return (
            <>
                {header && <MainFormHeader>{header}</MainFormHeader>}
                {this.props.topInterface}
                <Table
                    dataSource={data}
                    columns={isMobile ? mobileColumns : columns}
                    rowKey={({ id }) => id.toString()}
                    locale={{ emptyText }}
                    pagination={{
                        ...pagination,
                        showSizeChanger: false,
                    }}
                    loading={isFetching}
                    onChange={this.handleChange}
                    size={size}
                    rowSelection={{ ...rowSelection, preserveSelectedRowKeys: true }}
                    showHeader={!hideHeader}
                    scroll={{ x: 300 }}
                    expandedRowRender={displayExpandableRow ? expandedRowRenderer : undefined}
                />
            </>
        );
    }
}

const mapStateToProps = createSelector(
    getPaginationState,
    getPaginationFetchingStatus,
    getPaginationFilters,
    (pagination, isFetching, filters): PaginatedTableStateProps => ({
        pagination,
        isFetching,
        filters,
    }),
);

export const PaginatedTable = connect(mapStateToProps, {
    paginationRequest,
    clearPagination,
})(PaginatedTableComponent);
