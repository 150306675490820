import React from 'react';
import { Collapse, Table } from 'antd';

import { generateColumns } from 'common/helpers/utils';
import { CollapseWrapper } from 'common/components/styled';
import { Profile } from 'features/user/models/userModel';
import { BenefitOrganisationRole } from 'features/benefit/models/benefitModels';

const { Panel } = Collapse;

interface BenefitAssignedUsersProps {
    benefitAssessors: Profile[];
    benefitSuppliers: Profile[];
    benefitPartners: Profile[];
}

const projectManagerColumns = [
    {
        title: 'Name',
        key: 'userFullname',
        render: (_value: string, item: Profile) => <span>{item.userFullname}</span>,
    },
    {
        title: 'Organisation',
        key: 'organisationName',
        render: (_value: string, item: Profile) => <span>{item.organisationName}</span>,
    },
    {
        title: 'Email',
        key: 'email',
        render: (_value: string, item: Profile) => {
            const mailToLink = `mailto:${item.user.email}`;
            return (
                <span>
                    <a href={mailToLink}>{item.user.email}</a>
                </span>
            );
        },
    },
];

const executiveUserColumns = [
    {
        title: 'Name',
        key: 'userFullname',
        render: (_value: string, item: BenefitOrganisationRole) => <span>{item.userFullname}</span>,
    },
    {
        title: 'Organisation',
        key: 'organisationName',
        render: (_value: string, item: Profile) => <span>{item.organisationName}</span>,
    },
    {
        title: 'Email',
        key: 'email',
        render: (_value: string, item: BenefitOrganisationRole) => {
            const mailToLink = `mailto:${item.email}`;
            return (
                <span>
                    <a href={mailToLink}>{item.email}</a>
                </span>
            );
        },
    },
];

export const BenefitAssignedUsers: React.FunctionComponent<BenefitAssignedUsersProps> = ({
    benefitAssessors,
    benefitSuppliers,
    benefitPartners,
}) => (
    <>
        <CollapseWrapper>
            <Collapse>
                {benefitAssessors.length > 0 ? (
                    <Panel header="Contributors" key="1">
                        <Table
                            columns={generateColumns(projectManagerColumns)}
                            dataSource={benefitAssessors}
                        />
                    </Panel>
                ) : null}
                {benefitSuppliers.length > 0 ? (
                    <Panel header="Supplier Executives" key="2">
                        <Table
                            columns={generateColumns(executiveUserColumns)}
                            dataSource={benefitSuppliers}
                        />
                    </Panel>
                ) : null}
                {benefitPartners.length > 0 ? (
                    <Panel header="Delivery Partner Executives" key="3">
                        <Table
                            columns={generateColumns(executiveUserColumns)}
                            dataSource={benefitPartners}
                        />
                    </Panel>
                ) : null}
            </Collapse>
        </CollapseWrapper>
    </>
);
