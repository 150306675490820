import * as React from 'react';
import posthog from 'posthog-js';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Link } from 'react-router-dom';

import { POSTHOG_API_KEY, SENTRY_ENVIRONMENT } from 'config/env';
import { Benefit, EditBenefitPaths } from 'features/benefit/models/benefitModels';
import {
    generateColumns,
    DownloadFileFormats,
    idExtractorForFileDownload,
    addReferenceSuffixToBenfitOutcome,
    getStatusLabel,
} from 'common/helpers/utils';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { getBenefitsSuccess } from 'features/benefit/actions';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { FilterData } from 'common/helpers/url';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { PaddedSection } from 'common/components';
import { EditContractPaths } from 'features/contract/models/contractModels';
import { EditPaths } from 'features/edit/models/editModel';

import { DownloadBenefitsContainerProps } from '../containers/DownloadBenefitsContainer';
import { DownloadAll } from '../models/downloadModel';
import { DownloadTableFiltersBar } from './DownloadTableFiltersBar';

if (SENTRY_ENVIRONMENT === 'frontend-production') {
    if (POSTHOG_API_KEY) {
        posthog.init(POSTHOG_API_KEY, {
            api_host: 'https://eu.i.posthog.com',
            autocapture: false,
            capture_pageview: false,
        });
    }
}

export interface BenefitData extends Benefit {
    checked: boolean;
}

interface State {
    selectedBenefitIds: number[];
    isButtonDisabled: boolean;
    selectedAll: boolean;
    fileFormat: string;
    exportType: string;
    benefitType: string;
    tenantFilter: number;
}

interface DownloadBenefitsProps {
    redirectToUrl(path: string): void;
}

type Props = DownloadBenefitsContainerProps & DownloadBenefitsProps & Antd3FormProps;

export const downloadBenefitsColumns = (tenant: boolean) => [
    {
        title: 'Benefit',
        key: 'outcome',
        render: (outcome: string, { contract, id }: BenefitData) =>
            tenant ? (
                <Link
                    to={`${EditPaths.Contract}/${contract}/benefits/${id}${EditBenefitPaths.Details}`}
                >
                    {outcome}
                </Link>
            ) : (
                outcome
            ),
    },
    {
        title: 'Benefit Label',
        key: 'label',
        render: (label: string, { contract, id }: BenefitData) =>
            tenant ? (
                <Link
                    to={`${EditPaths.Contract}/${contract}/benefits/${id}${EditBenefitPaths.Details}`}
                >
                    {label}
                </Link>
            ) : (
                label
            ),
    },
    {
        title: 'Quantity',
        key: 'quantity',
        render: (quantity: BenefitData) => <span>{quantity}</span>,
    },
    {
        title: 'Status',
        key: 'status',
        render: (status: string) => getStatusLabel(status),
    },
    {
        title: 'Completion Date',
        key: 'finalDeliveryDate',
        render: (finalDeliveryDate: string) => finalDeliveryDate,
    },
    {
        title: 'Project',
        key: 'contractTitle',
        render: (contractTitle: string, { contract, referenceNumber }: BenefitData) =>
            tenant ? (
                <Link to={`${EditPaths.Contract}/${contract}${EditContractPaths.ContractDetails}`}>
                    {`${contractTitle} | ${referenceNumber} `}
                </Link>
            ) : (
                `${contractTitle} | ${referenceNumber} `
            ),
    },
];

class DownloadBenefitsForm extends React.Component<Props, State> {
    public state: State = {
        selectedBenefitIds: [],
        isButtonDisabled: true,
        selectedAll: false,
        fileFormat: DownloadFileFormats.XLSX,
        exportType: 'summary',
        benefitType: '',
        tenantFilter: 0,
    };

    private handleSubmit = ({ downloadAll = false }: DownloadAll = {}): void => {
        const benefitIdsForRequest = idExtractorForFileDownload(
            this.props.benefits,
            downloadAll,
            this.state.selectedBenefitIds,
        );

        this.props.getBenefitsCSVRequest({
            ids: benefitIdsForRequest,
            fileFormat: this.state.fileFormat,
            downloadAll,
            exportType: this.state.exportType,
            benefitType: this.state.benefitType,
        });
    };

    private downloadBenefits = (event: React.FormEvent): void => {
        event.preventDefault();

        this.handleSubmit();
    };

    private downloadAllBenefits = (event: React.FormEvent): void => {
        event.preventDefault();

        this.handleSubmit({ downloadAll: true });
    };

    private setFileFormat = (FileFormatChangeEvent: RadioChangeEvent) =>
        this.setState({ fileFormat: FileFormatChangeEvent.target.value });

    private setExportType = (ExportTypeChangeEvent: RadioChangeEvent) => {
        this.setState({ exportType: ExportTypeChangeEvent.target.value });
        this.clearSelectedBenefitIds();
    };

    private onSelectChange = (selectedBenefitIds: number[]) =>
        this.setState({ selectedBenefitIds });

    private refreshTable = (filters?: FilterData) =>
        this.props.paginationRequest({
            view: PaginationView.DownloadBenefits,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getBenefitsSuccess,
        });

    private saveBenefitFilters = (benefitReferenceNumber: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.DownloadBenefits,
            values: {
                referenceNumber: benefitReferenceNumber,
            },
        });

    private saveContractFilters = (contractId: number | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.DownloadBenefits,
            values: {
                contract: contractId,
            },
        });

    private saveBenefitTypeFilters = (benefitType: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.DownloadBenefits,
            values: {
                benefitType,
            },
        });

    private saveBenefitTenantFilters = (tenant: number | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.DownloadBenefits,
            values: {
                tenant,
            },
        });

    private searchByBenefits = (benefitReferenceNumber: string) => {
        this.saveBenefitFilters(benefitReferenceNumber);
        this.refreshTable();
    };

    private searchByContracts = (contractId: number) => {
        this.saveContractFilters(contractId);
        this.refreshTable();
    };

    private searchByBenefitType = (benefitType: string) => {
        this.saveBenefitTypeFilters(benefitType);
        this.refreshTable();
        this.setState({ benefitType });
        this.clearSelectedBenefitIds();
    };

    private searchByTenant = (tenant: number) => {
        this.setState({ tenantFilter: tenant });
        this.saveBenefitTenantFilters(tenant);
        this.refreshTable();
        this.clearSelectedBenefitIds();
    };

    private onRemoveTenantAutocomplete = () => {
        this.saveBenefitTenantFilters(undefined);
        this.refreshTable();
        this.setState({ tenantFilter: 0 });
        this.clearSelectedBenefitIds();
    };

    private onRemoveBenefitAutocomplete = () => {
        this.saveBenefitFilters(undefined);
        this.refreshTable();
    };

    private onRemoveContractAutocomplete = () => {
        this.saveContractFilters(undefined);
        this.refreshTable();
    };

    private clearFilters = () => {
        this.props.clearFilters(PaginationView.DownloadBenefits);
        this.refreshTable();
        this.setState({ benefitType: '' });
        this.props.form.setFieldsValue({
            quickFilterBenefitReferenceNumber: '',
            quickFilterContractReferenceNumber: '',
        });
    };

    private clearSelectedBenefitIds = () => {
        this.setState({ selectedBenefitIds: [] });
    };

    public render(): JSX.Element {
        const selectedCount = this.state.selectedBenefitIds.length;

        return (
            <PaddedSection header="Download Benefits">
                <DownloadTableFiltersBar
                    downloadAll={this.downloadAllBenefits}
                    downloadSelected={this.downloadBenefits}
                    setFileFormat={this.setFileFormat}
                    setExportType={this.setExportType}
                    exportType={this.state.exportType}
                    fileFormat={this.state.fileFormat}
                    selectedCount={selectedCount}
                    isFetchingCSV={this.props.isFetchingCSV}
                    filterId={PaginationView.DownloadBenefits}
                    searchByBenefits={this.searchByBenefits}
                    searchByContracts={this.searchByContracts}
                    searchByBenefitType={this.searchByBenefitType}
                    benefitType={this.state.benefitType}
                    searchByTenant={this.searchByTenant}
                    tenantFilter={this.state.tenantFilter}
                    onRemoveBenefitAutocomplete={this.onRemoveBenefitAutocomplete}
                    onRemoveContractAutocomplete={this.onRemoveContractAutocomplete}
                    onRemoveTenantAutocomplete={this.onRemoveTenantAutocomplete}
                    clearFilters={this.clearFilters}
                    form={this.props.form}
                    filter={this.props.filter}
                    isTenant={this.props.tenant}
                />
                <PaginatedTable
                    data={addReferenceSuffixToBenfitOutcome(this.props.benefits)}
                    columns={generateColumns(downloadBenefitsColumns(this.props.tenant))}
                    emptyText="No benefits found"
                    view={PaginationView.DownloadBenefits}
                    paginationSuccessCallback={getBenefitsSuccess}
                    rowSelection={{
                        selectedRowKeys: this.state.selectedBenefitIds,
                        onChange: this.onSelectChange,
                    }}
                />
            </PaddedSection>
        );
    }
}

export const DownloadBenefits = Antd3Form.create({})(DownloadBenefitsForm);
