import { Benefit, BenefitOrganisationRole } from 'features/benefit/models/benefitModels';
import { Contract } from 'features/contract/models/contractModels';
import { BenefitDetails as BaseBenefitDetails } from 'common/components/BenefitDetails';
import { Profile } from 'features/user/models/userModel';

import { BenefitAssignedUsers } from './BenefitAssignedUsers';

type Props = {
    benefit: Benefit;
    contract: Contract;
    benefitAssessors: Profile[];
    benefitSuppliers: BenefitOrganisationRole[];
    benefitPartners: BenefitOrganisationRole[];
    isEditable?: boolean;
};

export const BenefitDetails = ({
    benefit,
    contract,
    benefitAssessors,
    benefitSuppliers,
    benefitPartners,
    isEditable,
}: Props) => (
    <>
        {benefit && (
            <>
                {benefit.priorities.map((priority) => (
                    <p>{priority.name}</p>
                ))}
                <BaseBenefitDetails
                    benefit={benefit}
                    contract={contract}
                    benefitSuppliers={benefitSuppliers}
                    benefitPartners={benefitPartners}
                    showFinalDeliveryDate={true}
                />
                <BenefitAssignedUsers
                    benefitAssessors={benefitAssessors}
                    benefitSuppliers={benefitSuppliers}
                    benefitPartners={benefitPartners}
                />
            </>
        )}
    </>
);
