import { Collapse } from 'antd';
import { useDispatch } from 'react-redux';

import { SubmitButton } from 'common/components';
import { CollapseWrapper } from 'common/components/styled';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { editBenefitRequest } from 'features/benefit/actions';
import { Benefit } from 'features/benefit/models/benefitModels';
import { Contract } from 'features/contract/models/contractModels';
import { Antd3Form, StyledAntd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import moment from 'moment';
import { DatePicker } from 'common/components/deprecated/form';

interface BenefitReportingDateProps extends Antd3FormProps {
    benefit: Benefit;
    contract: Contract;
}

const EditBenefitReportingDateForm = ({ benefit, contract, form }: BenefitReportingDateProps) => {
    const dispatch = useDispatch();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        form.validateFields((error, { reportedOn, ...values }) => {
            if (error || !reportedOn) {
                return;
            }

            dispatch(
                editBenefitRequest({
                    ...values,
                    benefitId: benefit.id,
                    contract: contract.id,
                    sharedBenefitForUrl: benefit.sharedBenefit,
                    reportedOn: moment(reportedOn).format('YYYY-MM-DD'),
                }),
            );
        });
    };

    return (
        <CollapseWrapper>
            <Collapse>
                <Collapse.Panel header="Update Benefit Closure Date" key="reporting date">
                    <StyledAntd3Form onSubmit={handleSubmit}>
                        <DatePicker
                            form={form}
                            id="reportedOn"
                            label="Closure Date"
                            initialValue={
                                benefit.reportedOn &&
                                moment(benefit.reportedOn).format('YYYY-MM-DD')
                            }
                            required={false}
                        />
                        <SubmitButton value="Update Closure Date" />
                    </StyledAntd3Form>
                </Collapse.Panel>
            </Collapse>
        </CollapseWrapper>
    );
};

export const EditBenefitReportingDate = Antd3Form.create({})(EditBenefitReportingDateForm);
