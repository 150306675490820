import { Form, Tag } from 'antd';
import { Input } from 'common/components';
import { actionPropsByAction } from 'features/upload/helpers';
import { getMyEvidence } from 'features/upload/selectors/uploadSelectors';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import * as S from '../shared/styles';

type Props = {
    name: string;
};

export const BenefitLabelSuggestionCard = ({ name }: Props) => {
    const benefitLabel = Form.useWatch('benefitLabel');
    const myEvidence = useSelector(getMyEvidence);
    const [initialValue, setInitialValue] = useState(
        myEvidence?.benefitChangeSuggestions?.benefitLabel,
    );
    const isUpdated = benefitLabel !== initialValue;
    const action = isUpdated ? (initialValue ? 'update' : 'create') : '';
    const actionProps = action && actionPropsByAction[action];
    const displayChange = actionProps && (action !== 'update' || isUpdated);
    return (
        <S.SuggestionWrapper color={actionProps && actionProps.color}>
            {displayChange && (
                <S.ChangeInfo>
                    <Tag color={actionProps.color}>
                        {actionProps.label}
                        {isUpdated && (
                            <span>
                                {action === 'update' && `, previous Value: "${initialValue}"`}
                            </span>
                        )}
                    </Tag>
                </S.ChangeInfo>
            )}
            <Form.Item name={name}>
                <Input name={name} type="text" label={'Benefit Label'} required={false} />
            </Form.Item>
        </S.SuggestionWrapper>
    );
};
