import * as React from 'react';
import styled from 'styled-components';
import { List, Modal } from 'antd';
import * as _ from 'lodash';

import { colors, fontWeights } from 'theme/variables';
import { ListItem, FullPageSpinner, PaddedSection } from 'common/components';
import { titleCase } from 'common/helpers/titleCase';
import { formatProfileForDetails } from 'common/helpers/utils';
import { Detail } from 'features/contract/models/contractModels';
import { EditPaths } from 'features/edit/models/editModel';

import { BenefitDetailsProps } from '../containers/BenefitDetailsContainer';
import { BenefitOrganisationRole, BenefitStatus } from '../models/benefitModels';
import { PrioritiesWrapper } from './PrioritiesWrapper';
import { PriorityWrapper } from './PriorityWrapper';
import { PriorityName } from './PriorityName';

const ListWrapper = styled.div`
    width: 100%;
    background-color: ${colors.primaryWhite};

    .ant-list-header {
        background-color: ${colors.primary};
        color: ${colors.primaryWhite};
        font-weight: ${fontWeights.bold};
    }
`;

export const BenefitDetails: React.FunctionComponent<BenefitDetailsProps> = ({
    contract,
    contractId,
    benefit,
    benefitId,
    deleteBenefitRequest,
    redirectToUrl,
    isFetching,
    benefitAssessors,
    benefitSuppliers,
    benefitPartners,
    priorities,
}) => {
    if (isFetching) {
        return <FullPageSpinner />;
    }

    if (!(benefit && contract)) {
        return (
            <h2>
                No contract (id: {contractId}) and/or benefit (id: {benefitId}) found
            </h2>
        );
    }

    const formatRoles = (role: BenefitOrganisationRole[]) =>
        role.map(({ name }) => name).join(', ');

    const checkNone = (arr: BenefitOrganisationRole[]) => (arr.length ? formatRoles(arr) : 'None');

    const benefitDetails = [
        { name: 'Project Title', value: contract.title },
        { name: 'Project Reference Number', value: contract.referenceNumber },
        { name: 'Outcome', value: benefit.outcome },
        { name: 'Benefit Label', value: benefit.label },
        { name: 'Benefit Reference Number', value: benefit.referenceNumber || 'None' },
        { name: 'Benefit Description', value: benefit.description },
        { name: 'Benefit Category', value: benefit.categoryName },
        { name: 'Benefit Model', value: _.startCase(_.camelCase(benefit.method)) },
        { name: 'Benefit Location', value: benefit.locationName || 'None' },
        { name: 'Benefit Points', value: benefit.points },
        { name: 'Benefit Value', value: benefit.value },
        { name: 'Emissions Savings', value: benefit.emissionSavings },
        { name: 'Quantity', value: benefit.quantity },
        { name: 'Benefit Priorities', priorities },
        {
            name: 'Contributors',
            value: formatProfileForDetails(benefitAssessors),
        },
        {
            name: 'Supplier',
            value: checkNone(benefitSuppliers),
        },
        {
            name: 'Delivery Partners',
            value: checkNone(benefitPartners),
        },
        { name: 'Start Date', value: benefit.startDate },
        { name: 'Benefit Completion Date', value: benefit.finalDeliveryDate },
        { name: 'Benefit Closure Date', value: benefit.reportedOn },
        { name: 'Status', value: _.startCase(_.camelCase(benefit.status)) },
        { name: 'Status Explanation', value: benefit.statusExplanation || 'None' },
    ];

    const deliverablesDetails =
        titleCase(benefit.status) === BenefitStatus.PARTIALLY_DELIVERED
            ? [
                  { name: 'Points Delivered', value: benefit.deliveredPoints || 'None' },
                  { name: 'Value Delivered', value: benefit.deliveredValue || 'None' },
                  {
                      name: 'Emission Savings Delivered',
                      value: benefit.deliveredEmissionSavings || 'None',
                  },
              ]
            : [];

    const details = benefitDetails.concat(deliverablesDetails);

    const deleteBenefit = () =>
        Modal.confirm({
            title: 'If you delete this benefit you will also delete any evidence associated with it. Are you sure you want to continue?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                deleteBenefitRequest({ benefitId: benefit.id, contractId: benefit.contract });
                redirectToUrl(`${EditPaths.Contract}/${benefit.contract}/Benefits`);
            },
        });

    return (
        <PaddedSection>
            <ListWrapper>
                <List
                    header={
                        <ListItem
                            text="Benefit Details"
                            buttons={[
                                {
                                    name: 'Remove',
                                    icon: 'delete',
                                    type: 'danger',
                                    onClick: deleteBenefit,
                                },
                            ]}
                            header="true"
                        />
                    }
                    bordered
                    dataSource={details}
                    renderItem={({ name, value, priorities }: Detail) =>
                        priorities ? (
                            <PrioritiesWrapper>
                                {name}:
                                {priorities.map((priority) => (
                                    <PriorityWrapper key={priority.id}>
                                        <PriorityName>{priority.priorityName}:</PriorityName>
                                        <span>{priority.value}</span>
                                    </PriorityWrapper>
                                ))}
                            </PrioritiesWrapper>
                        ) : (
                            <List.Item>
                                {name}: {value}
                            </List.Item>
                        )
                    }
                />
            </ListWrapper>
        </PaddedSection>
    );
};
