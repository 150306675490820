import posthog from 'posthog-js';
import { Action } from 'common/actions/appAction';
import { Role } from 'features/user/models/userModel';
import {
    ArchiveFilterRequest,
    ArchiveFilterResponse,
    EditFilterRequest,
    Filters,
} from 'features/contract/models/contractModels';
import { InviteByEmailWithRole } from 'features/invite/models/inviteModel';
import { SENTRY_ENVIRONMENT } from 'config/env';

import {
    Benefit,
    BenefitRequest,
    ContractBenefit,
    BenefitPathParams,
    EditBenefitRequest,
    BenefitsRolesRequest,
    AssignEmployeeRequest,
    FilterBenefitRequest,
    FilteredBenefits,
    AddBenefitRoleRequest,
    DeleteBenefitRoleRequest,
    BenefitRoleData,
    BenefitDataRequest,
    FilterContractBenefitsRequest,
    FilteredContractBenefits,
    ChangeBenefitStatusRequest,
    ToggleMultipleEmployeesRoleRequest,
    BenefitPriority,
    EditBenefitPriorityRequest,
    AddBenefitPriorityRequest,
    BenefitPriorityDashboardItem,
    InvitePendingIndividualExecutivesRequest,
    BenefitNoteData,
    CreateBenefitNoteRequest,
    DeleteBenefitNoteRequest,
    EditBenefitNoteRequest,
    DeleteBenefitNoteFileRequest,
    AddBenefitNoteFileRequest,
    GetBenefitNoteRequest,
    ToggleMultipleInvitePendingExecutiveUsersRoleRequest,
} from '../models/benefitModels';

export const ASSIGN_EMPLOYEES_REQUESTED = 'ASSIGN_EMPLOYEES_REQUESTED';
export type AssignEmployeesRequestAction = Action<
    typeof ASSIGN_EMPLOYEES_REQUESTED,
    AssignEmployeeRequest[]
>;
export const assignEmployeesRequest = (
    employeesData: AssignEmployeeRequest[],
): AssignEmployeesRequestAction => ({
    type: ASSIGN_EMPLOYEES_REQUESTED,
    payload: employeesData,
});

export const ASSIGN_EMPLOYEES_SUCCEED = 'ASSIGN_EMPLOYEES_SUCCEED';
export type AssignEmployeesSuccessAction = Action<typeof ASSIGN_EMPLOYEES_SUCCEED, null, string>;
export const assignEmployeesSuccess = (): AssignEmployeesSuccessAction => ({
    type: ASSIGN_EMPLOYEES_SUCCEED,
    meta: 'Employee assigned to benefit(s)',
});

export const ASSIGN_EMPLOYEES_FAILED = 'ASSIGN_EMPLOYEES_FAILED';
export type AssignEmployeesFailureAction = Action<typeof ASSIGN_EMPLOYEES_FAILED, string>;
export const assignEmployeesFailure = (payload: string): AssignEmployeesFailureAction => ({
    type: ASSIGN_EMPLOYEES_FAILED,
    payload,
});

export const UNASSIGN_EMPLOYEES_REQUESTED = 'UNASSIGN_EMPLOYEES_REQUESTED';
export type UnassignEmployeesRequestAction = Action<
    typeof UNASSIGN_EMPLOYEES_REQUESTED,
    AssignEmployeeRequest[]
>;
export const unassignEmployeesRequest = (
    employeesData: AssignEmployeeRequest[],
): UnassignEmployeesRequestAction => ({
    type: UNASSIGN_EMPLOYEES_REQUESTED,
    payload: employeesData,
});

export const UNASSIGN_EMPLOYEES_SUCCEED = 'UNASSIGN_EMPLOYEES_SUCCEED';
export type UnassignEmployeesSuccessAction = Action<typeof UNASSIGN_EMPLOYEES_SUCCEED, any, string>;
export const unassignEmployeesSuccess = (): UnassignEmployeesSuccessAction => ({
    type: UNASSIGN_EMPLOYEES_SUCCEED,
    meta: 'Employee unassigned from benefit(s)',
});

export const UNASSIGN_EMPLOYEES_FAILED = 'UNASSIGN_EMPLOYEES_FAILED';
export type UnassignEmployeesFailureAction = Action<typeof UNASSIGN_EMPLOYEES_FAILED, string>;
export const unassignEmployeesFailure = (payload: string): UnassignEmployeesFailureAction => ({
    type: UNASSIGN_EMPLOYEES_FAILED,
    payload,
});

export const CREATE_BENEFIT_REQUESTED = 'CREATE_BENEFIT_REQUESTED';
export type CreateBenefitRequestAction = Action<typeof CREATE_BENEFIT_REQUESTED, BenefitRequest>;
export const createBenefitRequest = (benefit: BenefitRequest): CreateBenefitRequestAction => ({
    type: CREATE_BENEFIT_REQUESTED,
    payload: benefit,
});

export const CREATE_BENEFIT_SUCCEED = 'CREATE_BENEFIT_SUCCEED';
export type CreateBenefitSuccessAction = Action<
    typeof CREATE_BENEFIT_SUCCEED,
    {
        newUsers: InviteByEmailWithRole[];
        assignInvitePendingUsers: InvitePendingIndividualExecutivesRequest[];
    },
    string
>;
export const createBenefitSuccess = (
    newUsers: InviteByEmailWithRole[],
    assignInvitePendingUsers: InvitePendingIndividualExecutivesRequest[],
): CreateBenefitSuccessAction => ({
    type: CREATE_BENEFIT_SUCCEED,
    payload: { newUsers, assignInvitePendingUsers },
    meta: 'Benefit created',
});

export const CREATE_BENEFIT_FAILED = 'CREATE_BENEFIT_FAILED';
export type CreateBenefitFailureAction = Action<typeof CREATE_BENEFIT_FAILED, string>;
export const createBenefitFailure = (payload: string): CreateBenefitFailureAction => ({
    type: CREATE_BENEFIT_FAILED,
    payload,
});

export const DELETE_BENEFIT_REQUESTED = 'DELETE_BENEFIT_REQUESTED';
export type DeleteBenefitRequest = Action<typeof DELETE_BENEFIT_REQUESTED, BenefitPathParams>;
export const deleteBenefitRequest = (data: BenefitPathParams): DeleteBenefitRequest => ({
    type: DELETE_BENEFIT_REQUESTED,
    payload: data,
});

export const DELETE_BENEFIT_SUCCEED = 'DELETE_BENEFIT_SUCCEED';
export type DeleteBenefitSuccessAction = Action<typeof DELETE_BENEFIT_SUCCEED, number, string>;
export const deleteBenefitSuccess = (benefitId: number): DeleteBenefitSuccessAction => ({
    type: DELETE_BENEFIT_SUCCEED,
    payload: benefitId,
    meta: 'Benefit deleted',
});

export const DELETE_BENEFIT_FAILED = 'DELETE_BENEFIT_FAILED';
export type DeleteBenefitFailureAction = Action<typeof DELETE_BENEFIT_FAILED, string>;
export const deleteBenefitFailure = (payload: string): DeleteBenefitFailureAction => ({
    type: DELETE_BENEFIT_FAILED,
    payload,
});

export const EDIT_BENEFIT_REQUESTED = 'EDIT_BENEFIT_REQUESTED';
export type EditBenefitRequestAction = Action<typeof EDIT_BENEFIT_REQUESTED, EditBenefitRequest>;
export const editBenefitRequest = (benefit: EditBenefitRequest): EditBenefitRequestAction => ({
    type: EDIT_BENEFIT_REQUESTED,
    payload: benefit,
});

export const EDIT_BENEFIT_SUCCEED = 'EDIT_BENEFIT_SUCCEED';
export type EditBenefitSuccessAction = Action<typeof EDIT_BENEFIT_SUCCEED, Benefit, string>;
export const editBenefitSuccess = (editedBenefit: Benefit): EditBenefitSuccessAction => ({
    type: EDIT_BENEFIT_SUCCEED,
    meta: 'Benefit edited',
    payload: editedBenefit,
});

export const EDIT_BENEFIT_FAILED = 'EDIT_BENEFIT_FAILED';
export type EditBenefitFailureAction = Action<typeof EDIT_BENEFIT_FAILED, string>;
export const editBenefitFailure = (payload: string): EditBenefitFailureAction => ({
    type: EDIT_BENEFIT_FAILED,
    payload,
});

export const EDIT_BENEFIT_PRIORITY_REQUESTED = 'EDIT_BENEFIT_PRIORITY_REQUESTED';
export type EditBenefitPriorityRequestAction = Action<
    typeof EDIT_BENEFIT_PRIORITY_REQUESTED,
    EditBenefitPriorityRequest
>;
export const editBenefitPriorityRequest = (
    priority: EditBenefitPriorityRequest,
): EditBenefitPriorityRequestAction => ({
    type: EDIT_BENEFIT_PRIORITY_REQUESTED,
    payload: priority,
});

export const EDIT_BENEFIT_PRIORITY_SUCCEED = 'EDIT_BENEFIT_PRIORITY_SUCCEED';
export type EditBenefitPrioritySuccessAction = Action<
    typeof EDIT_BENEFIT_PRIORITY_SUCCEED,
    BenefitPriority,
    string
>;
export const editBenefitPrioritySuccess = (
    priority: BenefitPriority,
): EditBenefitPrioritySuccessAction => ({
    type: EDIT_BENEFIT_PRIORITY_SUCCEED,
    payload: priority,
    meta: 'Priority updated',
});

export const EDIT_BENEFIT_PRIORITY_FAILED = 'EDIT_BENEFIT_PRIORITY_FAILED';
export type EditBenefitPriorityFailureAction = Action<typeof EDIT_BENEFIT_PRIORITY_FAILED, string>;
export const editBenefitPriorityFail = (payload: string): EditBenefitPriorityFailureAction => ({
    type: EDIT_BENEFIT_PRIORITY_FAILED,
    payload,
});

export const DELETE_BENEFIT_PRIORITY_REQUESTED = 'DELETE_BENEFIT_PRIORITY_REQUESTED';
export type DeleteBenefitPriorityRequestAction = Action<
    typeof DELETE_BENEFIT_PRIORITY_REQUESTED,
    EditBenefitPriorityRequest
>;
export const deleteBenefitPriorityRequest = (
    data: EditBenefitPriorityRequest,
): DeleteBenefitPriorityRequestAction => ({
    type: DELETE_BENEFIT_PRIORITY_REQUESTED,
    payload: data,
});

export const DELETE_BENEFIT_PRIORITY_SUCCEED = 'DELETE_BENEFIT_PRIORITY_SUCCEED';
export type DeleteBenefitPrioritySuccessAction = Action<
    typeof DELETE_BENEFIT_PRIORITY_SUCCEED,
    number,
    string
>;
export const deleteBenefitPrioritySuccess = (
    benefitId: number,
): DeleteBenefitPrioritySuccessAction => ({
    type: DELETE_BENEFIT_PRIORITY_SUCCEED,
    payload: benefitId,
    meta: 'Benefit priority deleted',
});

export const DELETE_BENEFIT_PRIORITY_FAILED = 'DELETE_BENEFIT_PRIORITY_FAILED';
export type DeleteBenefitPriorityFailureAction = Action<
    typeof DELETE_BENEFIT_PRIORITY_FAILED,
    string
>;
export const deleteBenefitPriorityFailure = (
    payload: string,
): DeleteBenefitPriorityFailureAction => ({
    type: DELETE_BENEFIT_PRIORITY_FAILED,
    payload,
});

export const GET_BENEFIT_PRIORITY_DASHBOARD_SUCCEED = 'GET_BENEFIT_PRIORITY_DASHBOARD_SUCCEED';
export type GetBenefitPriorityDashboardSuccessAction = Action<
    typeof GET_BENEFIT_PRIORITY_DASHBOARD_SUCCEED,
    { priorityDashboard: BenefitPriorityDashboardItem[] }
>;
export const getBenefitPriorityDashboardSuccess = (
    priorityDashboard: BenefitPriorityDashboardItem[],
): GetBenefitPriorityDashboardSuccessAction => ({
    type: GET_BENEFIT_PRIORITY_DASHBOARD_SUCCEED,
    payload: { priorityDashboard },
});

export const FILTER_BENEFITS_REQUESTED = 'FILTER_BENEFITS_REQUESTED';
export type FilterBenefitsRequestAction = Action<
    typeof FILTER_BENEFITS_REQUESTED,
    FilterBenefitRequest
>;
export const filterBenefitsRequest = (
    benefit: FilterBenefitRequest,
): FilterBenefitsRequestAction => ({
    type: FILTER_BENEFITS_REQUESTED,
    payload: benefit,
});

export const FILTER_BENEFITS_SUCCEED = 'FILTER_BENEFITS_SUCCEED';
export type FilterBenefitsSuccessAction = Action<typeof FILTER_BENEFITS_SUCCEED, FilteredBenefits>;
export const filterBenefitsSuccess = (benefits: FilteredBenefits): FilterBenefitsSuccessAction => ({
    type: FILTER_BENEFITS_SUCCEED,
    payload: benefits,
});

export const FILTER_BENEFITS_FAILED = 'FILTER_BENEFITS_FAILED';
export type FilterBenefitsFailureAction = Action<typeof FILTER_BENEFITS_FAILED, string>;
export const filterBenefitsFailure = (payload: string): FilterBenefitsFailureAction => ({
    type: FILTER_BENEFITS_FAILED,
    payload,
});

export const FILTER_CONTRACT_BENEFITS_REQUESTED = 'FILTER_CONTRACT_BENEFITS_REQUESTED';
export type FilterContractBenefitsRequestAction = Action<
    typeof FILTER_CONTRACT_BENEFITS_REQUESTED,
    FilterContractBenefitsRequest
>;
export const filterContractBenefitsRequest = (
    benefit: FilterContractBenefitsRequest,
): FilterContractBenefitsRequestAction => ({
    type: FILTER_CONTRACT_BENEFITS_REQUESTED,
    payload: benefit,
});

export const FILTER_CONTRACT_BENEFITS_SUCCEED = 'FILTER_CONTRACT_BENEFITS_SUCCEED';
export type FilterContractBenefitsSuccessAction = Action<
    typeof FILTER_CONTRACT_BENEFITS_SUCCEED,
    FilteredContractBenefits
>;
export const filterContractBenefitsSuccess = (
    contractBenefits: FilteredContractBenefits,
): FilterContractBenefitsSuccessAction => ({
    type: FILTER_CONTRACT_BENEFITS_SUCCEED,
    payload: contractBenefits,
});

export const FILTER_CONTRACT_BENEFITS_FAILED = 'FILTER_CONTRACT_BENEFITS_FAILED';
export type FilterContractBenefitsFailureAction = Action<
    typeof FILTER_CONTRACT_BENEFITS_FAILED,
    string
>;
export const filterContractBenefitsFailure = (
    payload: string,
): FilterContractBenefitsFailureAction => ({
    type: FILTER_CONTRACT_BENEFITS_FAILED,
    payload,
});

export const RESET_FILTERED_CONTRACT_ID = 'RESET_FILTERED_CONTRACT_ID';
export type ResetFilteredContractIdAction = Action<typeof RESET_FILTERED_CONTRACT_ID>;
export const resetFilteredContractId = (): ResetFilteredContractIdAction => ({
    type: RESET_FILTERED_CONTRACT_ID,
});

export const GET_BENEFITS_SUCCEED = 'GET_BENEFITS_SUCCEED';
export type GetBenefitsSuccessAction = Action<typeof GET_BENEFITS_SUCCEED, { benefits: Benefit[] }>;
export const getBenefitsSuccess = (benefits: Benefit[]): GetBenefitsSuccessAction => ({
    type: GET_BENEFITS_SUCCEED,
    payload: { benefits },
});

export const GET_MY_BENEFITS_REQUESTED = 'GET_MY_BENEFITS_REQUESTED';
export type GetMyBenefitsRequestAction = Action<typeof GET_MY_BENEFITS_REQUESTED>;
export const getMyBenefitsRequest = (): GetMyBenefitsRequestAction => ({
    type: GET_MY_BENEFITS_REQUESTED,
});

export const GET_MY_BENEFITS_SUCCEED = 'GET_MY_BENEFITS_SUCCEED';
export type GetMyBenefitsSuccessAction = Action<
    typeof GET_MY_BENEFITS_SUCCEED,
    { myBenefits: Benefit[] }
>;
export const getMyBenefitsSuccess = (myBenefits: Benefit[]): GetMyBenefitsSuccessAction => ({
    type: GET_MY_BENEFITS_SUCCEED,
    payload: { myBenefits },
});

export const GET_MY_BENEFITS_FAILED = 'GET_MY_BENEFITS_FAILED';
export type GetMyBenefitsFailureAction = Action<typeof GET_MY_BENEFITS_FAILED, string>;
export const getMyBenefitsFailure = (payload: string): GetMyBenefitsFailureAction => ({
    type: GET_MY_BENEFITS_FAILED,
    payload,
});

export const GET_BENEFIT_CATEGORIES_REQUESTED = 'GET_BENEFIT_CATEGORIES_REQUESTED';
export type GetBenefitCategoriesRequestAction = Action<typeof GET_BENEFIT_CATEGORIES_REQUESTED>;
export const getBenefitCategoriesRequest = (): GetBenefitCategoriesRequestAction => ({
    type: GET_BENEFIT_CATEGORIES_REQUESTED,
});

export const GET_BENEFIT_CATEGORIES_SUCCEED = 'GET_BENEFIT_CATEGORIES_SUCCEED';
export type GetBenefitCategoriesSuccessAction = Action<
    typeof GET_BENEFIT_CATEGORIES_SUCCEED,
    { benefitCategories: Benefit[] }
>;
export const getBenefitCategoriesSuccess = (
    benefitCategories: Benefit[],
): GetBenefitCategoriesSuccessAction => ({
    type: GET_BENEFIT_CATEGORIES_SUCCEED,
    payload: { benefitCategories },
});

export const GET_BENEFIT_CATEGORIES_FAILED = 'GET_BENEFIT_CATEGORIES_FAILED';
export type GetBenefitCategoriesFailureAction = Action<
    typeof GET_BENEFIT_CATEGORIES_FAILED,
    string
>;
export const getBenefitCategoriesFailure = (
    payload: string,
): GetBenefitCategoriesFailureAction => ({
    type: GET_BENEFIT_CATEGORIES_FAILED,
    payload,
});

export const GET_BENEFITS_ROLES_REQUESTED = 'GET_BENEFITS_ROLES_REQUESTED';
export type GetBenefitsRolesRequestAction = Action<
    typeof GET_BENEFITS_ROLES_REQUESTED,
    BenefitsRolesRequest
>;
export const getBenefitsRolesRequest = (
    payload: BenefitsRolesRequest,
): GetBenefitsRolesRequestAction => ({
    type: GET_BENEFITS_ROLES_REQUESTED,
    payload,
});

export const GET_BENEFITS_ROLES_SUCCEED = 'GET_BENEFITS_ROLES_SUCCEED';
export type GetBenefitsRolesSuccessAction = Action<
    typeof GET_BENEFITS_ROLES_SUCCEED,
    { benefitRoles: Role[] }
>;
export const getBenefitsRolesSuccess = (benefitRoles: Role[]): GetBenefitsRolesSuccessAction => ({
    type: GET_BENEFITS_ROLES_SUCCEED,
    payload: { benefitRoles },
});

export const GET_BENEFITS_ROLES_FAILED = 'GET_BENEFITS_ROLES_FAILED';
export type GetBenefitsRolesFailureAction = Action<typeof GET_BENEFITS_ROLES_FAILED, string>;
export const getBenefitsRolesFailure = (payload: string): GetBenefitsRolesFailureAction => ({
    type: GET_BENEFITS_ROLES_FAILED,
    payload,
});

export const GET_BENEFIT_PRIORITIES_REQUESTED = 'GET_BENEFIT_PRIORITIES_REQUESTED';
export type GetBenefitPrioritiesRequestAction = Action<
    typeof GET_BENEFIT_PRIORITIES_REQUESTED,
    BenefitPathParams
>;
export const getBenefitPrioritiesRequest = (
    payload: BenefitPathParams,
): GetBenefitPrioritiesRequestAction => ({
    type: GET_BENEFIT_PRIORITIES_REQUESTED,
    payload,
});

export const GET_BENEFIT_PRIORITIES_SUCCEED = 'GET_BENEFIT_PRIORITIES_SUCCEED';
export type GetBenefitPrioritiesSuccessAction = Action<
    typeof GET_BENEFIT_PRIORITIES_SUCCEED,
    { priorities: BenefitPriority[] }
>;
export const getBenefitPrioritiesSuccess = (
    priorities: BenefitPriority[],
): GetBenefitPrioritiesSuccessAction => ({
    type: GET_BENEFIT_PRIORITIES_SUCCEED,
    payload: { priorities },
});

export const GET_BENEFIT_PRIORITIES_FAILED = 'GET_BENEFIT_PRIORITIES_FAILED';
export type GetBenefitPrioritiesFailureAction = Action<
    typeof GET_BENEFIT_PRIORITIES_FAILED,
    string
>;
export const getBenefitPrioritiesFailure = (
    payload: string,
): GetBenefitPrioritiesFailureAction => ({
    type: GET_BENEFIT_PRIORITIES_FAILED,
    payload,
});

export const ADD_BENEFIT_PRIORITY_REQUESTED = 'ADD_BENEFIT_PRIORITY_REQUESTED';
export type AddBenefitPriorityRequestAction = Action<
    typeof ADD_BENEFIT_PRIORITY_REQUESTED,
    AddBenefitPriorityRequest
>;
export const addBenefitPriorityRequest = (
    payload: AddBenefitPriorityRequest,
): AddBenefitPriorityRequestAction => ({
    type: ADD_BENEFIT_PRIORITY_REQUESTED,
    payload,
});

export const ADD_BENEFIT_PRIORITY_SUCCEED = 'ADD_BENEFIT_PRIORITY_SUCCEED';
export type AddBenefitPrioritySuccessAction = Action<
    typeof ADD_BENEFIT_PRIORITY_SUCCEED,
    { priorities: BenefitPriority[] }
>;
export const addBenefitPrioritySuccess = (
    priorities: BenefitPriority[],
): AddBenefitPrioritySuccessAction => ({
    type: ADD_BENEFIT_PRIORITY_SUCCEED,
    payload: { priorities },
});

export const ADD_BENEFIT_PRIORITY_FAILED = 'ADD_BENEFIT_PRIORITY_FAILED';
export type AddBenefitPriorityFailureAction = Action<typeof ADD_BENEFIT_PRIORITY_FAILED, string>;
export const addBenefitPriorityFailure = (payload: string): AddBenefitPriorityFailureAction => ({
    type: ADD_BENEFIT_PRIORITY_FAILED,
    payload,
});

export const GET_CONTRACT_BENEFITS_REQUESTED = 'GET_CONTRACT_BENEFITS_REQUESTED';
export type GetContractBenefitsRequestAction = Action<
    typeof GET_CONTRACT_BENEFITS_REQUESTED,
    number
>;
export const getContractBenefitsRequest = (id: number): GetContractBenefitsRequestAction => ({
    type: GET_CONTRACT_BENEFITS_REQUESTED,
    payload: id,
});

export const GET_CONTRACT_BENEFITS_SUCCEED = 'GET_CONTRACT_BENEFITS_SUCCEED';
export type GetContractBenefitsSuccessAction = Action<
    typeof GET_CONTRACT_BENEFITS_SUCCEED,
    { contractBenefits: ContractBenefit[] }
>;
export const getContractBenefitsSuccess = (
    contractBenefits: ContractBenefit[],
): GetContractBenefitsSuccessAction => ({
    type: GET_CONTRACT_BENEFITS_SUCCEED,
    payload: { contractBenefits },
});

export const GET_CONTRACT_BENEFITS_FAILED = 'GET_CONTRACT_BENEFITS_FAILED';
export type GetContractBenefitsFailureAction = Action<typeof GET_CONTRACT_BENEFITS_FAILED, string>;
export const getContractBenefitsFailure = (payload: string): GetContractBenefitsFailureAction => ({
    type: GET_CONTRACT_BENEFITS_FAILED,
    payload,
});

export const GET_CONTRACT_BENEFIT_DATA_REQUESTED = 'GET_CONTRACT_BENEFIT_DATA_REQUESTED';
export type GetContractBenefitDataRequestAction = Action<
    typeof GET_CONTRACT_BENEFIT_DATA_REQUESTED,
    BenefitDataRequest
>;
export const getContractBenefitDataRequest = (
    payload: BenefitDataRequest,
): GetContractBenefitDataRequestAction => ({
    type: GET_CONTRACT_BENEFIT_DATA_REQUESTED,
    payload,
});

export const GET_CONTRACT_BENEFIT_DATA_SUCCEED = 'GET_CONTRACT_BENEFIT_DATA_SUCCEED';
export type GetContractBenefitDataSuccessAction = Action<
    typeof GET_CONTRACT_BENEFIT_DATA_SUCCEED,
    ContractBenefit
>;
export const getContractBenefitDataSuccess = (
    payload: ContractBenefit,
): GetContractBenefitDataSuccessAction => ({
    type: GET_CONTRACT_BENEFIT_DATA_SUCCEED,
    payload,
});

export const GET_CONTRACT_BENEFIT_DATA_FAILED = 'GET_CONTRACT_BENEFIT_DATA_FAILED';
export type GetContractBenefitDataFailureAction = Action<
    typeof GET_CONTRACT_BENEFIT_DATA_FAILED,
    string
>;
export const getContractBenefitDataFailure = (
    payload: string,
): GetContractBenefitDataFailureAction => ({
    type: GET_CONTRACT_BENEFIT_DATA_FAILED,
    payload,
});

export const ADD_BENEFIT_ROLE_REQUESTED = 'ADD_BENEFIT_ROLE_REQUESTED';
export type AddBenefitRoleRequestAction = Action<
    typeof ADD_BENEFIT_ROLE_REQUESTED,
    AddBenefitRoleRequest
>;
export const addBenefitRoleRequest = (
    addBenefitRole: AddBenefitRoleRequest,
): AddBenefitRoleRequestAction => ({
    type: ADD_BENEFIT_ROLE_REQUESTED,
    payload: addBenefitRole,
});

export const ADD_BENEFIT_ROLE_SUCCEED = 'ADD_BENEFIT_ROLE_SUCCEED';
export type AddBenefitRoleSuccessAction = Action<
    typeof ADD_BENEFIT_ROLE_SUCCEED,
    BenefitRoleData,
    string
>;
export const addBenefitRoleSuccess = (role: BenefitRoleData): AddBenefitRoleSuccessAction => ({
    type: ADD_BENEFIT_ROLE_SUCCEED,
    payload: role,
    meta: 'Role added',
});

export const ADD_BENEFIT_ROLE_FAILED = 'ADD_BENEFIT_ROLE_FAILED';
export type AddBenefitRoleFailureAction = Action<typeof ADD_BENEFIT_ROLE_FAILED, string>;
export const addBenefitRoleFail = (payload: string): AddBenefitRoleFailureAction => ({
    type: ADD_BENEFIT_ROLE_FAILED,
    payload,
});

export const DELETE_BENEFIT_ROLE_REQUESTED = 'DELETE_BENEFIT_ROLE_REQUESTED';
export type DeleteBenefitRoleRequestAction = Action<
    typeof DELETE_BENEFIT_ROLE_REQUESTED,
    DeleteBenefitRoleRequest
>;
export const deleteBenefitRoleRequest = (
    deleteBenefitRole: DeleteBenefitRoleRequest,
): DeleteBenefitRoleRequestAction => ({
    type: DELETE_BENEFIT_ROLE_REQUESTED,
    payload: deleteBenefitRole,
});

export const DELETE_BENEFIT_ROLE_SUCCEED = 'DELETE_BENEFIT_ROLE_SUCCEED';
export type DeleteBenefitRoleSuccessAction = Action<
    typeof DELETE_BENEFIT_ROLE_SUCCEED,
    DeleteBenefitRoleRequest,
    string
>;
export const deleteBenefitRoleSuccess = (
    payload: DeleteBenefitRoleRequest,
): DeleteBenefitRoleSuccessAction => ({
    type: DELETE_BENEFIT_ROLE_SUCCEED,
    payload,
    meta: 'Role deleted',
});

export const DELETE_BENEFIT_ROLE_FAILED = 'DELETE_BENEFIT_ROLE_FAILED';
export type DeleteBenefitRoleFailureAction = Action<typeof DELETE_BENEFIT_ROLE_FAILED, string>;
export const deleteBenefitRoleFail = (payload: string): DeleteBenefitRoleFailureAction => ({
    type: DELETE_BENEFIT_ROLE_FAILED,
    payload,
});

export const ARCHIVE_BENEFIT_CATEGORY_REQUESTED = 'ARCHIVE_BENEFIT_CATEGORY_REQUESTED';
export type ArchiveBenefitCategoryRequestAction = Action<
    typeof ARCHIVE_BENEFIT_CATEGORY_REQUESTED,
    ArchiveFilterRequest
>;
export const archiveBenefitCategoryRequest = (
    filterData: ArchiveFilterRequest,
): ArchiveBenefitCategoryRequestAction => ({
    type: ARCHIVE_BENEFIT_CATEGORY_REQUESTED,
    payload: filterData,
});

export const ARCHIVE_BENEFIT_CATEGORY_SUCCEED = 'ARCHIVE_BENEFIT_CATEGORY_SUCCEED';
export type ArchiveBenefitCategorySuccessAction = Action<
    typeof ARCHIVE_BENEFIT_CATEGORY_SUCCEED,
    number,
    string
>;
export const archiveBenefitCategorySuccess = (
    filterData: ArchiveFilterResponse,
): ArchiveBenefitCategorySuccessAction => ({
    type: ARCHIVE_BENEFIT_CATEGORY_SUCCEED,
    payload: filterData.id,
    meta: `Benefit category ${filterData.name} ${filterData.archived ? 'archived' : 'restored'}`,
});

export const ARCHIVE_BENEFIT_CATEGORY_FAILED = 'ARCHIVE_BENEFIT_CATEGORY_FAILED';
export type ArchiveBenefitCategoryFailureAction = Action<
    typeof ARCHIVE_BENEFIT_CATEGORY_FAILED,
    string
>;
export const archiveBenefitCategoryFailure = (
    payload: string,
): ArchiveBenefitCategoryFailureAction => ({
    type: ARCHIVE_BENEFIT_CATEGORY_FAILED,
    payload,
});

export const CHANGE_BENEFIT_STATUS_REQUESTED = 'CHANGE_BENEFIT_STATUS_REQUESTED';
export type ChangeBenefitStatusRequestAction = Action<
    typeof CHANGE_BENEFIT_STATUS_REQUESTED,
    ChangeBenefitStatusRequest
>;
export const changeBenefitStatusRequest = (
    request: ChangeBenefitStatusRequest,
): ChangeBenefitStatusRequestAction => ({
    type: CHANGE_BENEFIT_STATUS_REQUESTED,
    payload: request,
});

export const CHANGE_BENEFIT_STATUS_SUCCEED = 'CHANGE_BENEFIT_STATUS_SUCCEED';
export type ChangeBenefitStatusSuccessAction = Action<
    typeof CHANGE_BENEFIT_STATUS_SUCCEED,
    Benefit,
    string
>;
export const changeBenefitStatusSuccess = (benefit: Benefit): ChangeBenefitStatusSuccessAction => ({
    type: CHANGE_BENEFIT_STATUS_SUCCEED,
    payload: benefit,
    meta: 'Status changed',
});

export const CHANGE_BENEFIT_STATUS_FAILED = 'CHANGE_BENEFIT_STATUS_FAILED';
export type ChangeBenefitStatusFailureAction = Action<typeof CHANGE_BENEFIT_STATUS_FAILED, string>;
export const changeBenefitStatusFailure = (payload: string): ChangeBenefitStatusFailureAction => ({
    type: CHANGE_BENEFIT_STATUS_FAILED,
    payload,
});

export const TOGGLE_MULTIPLE_EMPLOYEES_ROLE_REQUESTED = 'TOGGLE_MULTIPLE_EMPLOYEES_ROLE_REQUESTED';
export type ToggleMultipleEmployeesRoleRequestAction = Action<
    typeof TOGGLE_MULTIPLE_EMPLOYEES_ROLE_REQUESTED,
    ToggleMultipleEmployeesRoleRequest
>;
export const toggleMultipleEmployeesRoleRequest = (
    assignMultiple: ToggleMultipleEmployeesRoleRequest,
): ToggleMultipleEmployeesRoleRequestAction => ({
    type: TOGGLE_MULTIPLE_EMPLOYEES_ROLE_REQUESTED,
    payload: assignMultiple,
});

export const TOGGLE_MULTIPLE_EMPLOYEES_ROLE_SUCCEED = 'TOGGLE_MULTIPLE_EMPLOYEES_ROLE_SUCCEED';
export type ToggleMultipleEmployeesRoleSuccessAction = Action<
    typeof TOGGLE_MULTIPLE_EMPLOYEES_ROLE_SUCCEED,
    null,
    string,
    posthog.CaptureResult | null
>;

export const toggleMultipleEmployeesRoleSuccess = (
    meta: string,
): ToggleMultipleEmployeesRoleSuccessAction => ({
    type: TOGGLE_MULTIPLE_EMPLOYEES_ROLE_SUCCEED,
    meta,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Toggle (Assign/Unassign) Employee')
            : null,
});

export const TOGGLE_MULTIPLE_EMPLOYEES_ROLE_FAILED = 'TOGGLE_MULTIPLE_EMPLOYEES_ROLE_FAILED';
export type ToggleMultipleEmployeesRoleFailureAction = Action<
    typeof TOGGLE_MULTIPLE_EMPLOYEES_ROLE_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const toggleMultipleEmployeesRoleFailure = (
    payload: string,
): ToggleMultipleEmployeesRoleFailureAction => ({
    type: TOGGLE_MULTIPLE_EMPLOYEES_ROLE_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Toggle (Assign/Unassign) Employee Failed')
            : null,
});

export const SELECT_BENEFIT_CONTRACT = 'SELECT_BENEFIT_CONTRACT';
export type SelectBenefitContractAction = Action<typeof SELECT_BENEFIT_CONTRACT, number>;
export const selectBenefitContract = (contractId: number): SelectBenefitContractAction => ({
    type: SELECT_BENEFIT_CONTRACT,
    payload: contractId,
});

export const SET_DATE_RANGE_BENEFIT_PRIORITIES_DASHBOARD =
    'SET_DATE_RANGE_BENEFIT_PRIORITY_DASHBOARD';
export type SetDateRangeBenefitPrioritiesDashboardAction = Action<
    typeof SET_DATE_RANGE_BENEFIT_PRIORITIES_DASHBOARD,
    string[]
>;
export const setDateRangeBenefitPriorityDashboard = (
    benefitPrioritiesFilterDateRange: string[],
): SetDateRangeBenefitPrioritiesDashboardAction => ({
    type: SET_DATE_RANGE_BENEFIT_PRIORITIES_DASHBOARD,
    payload: benefitPrioritiesFilterDateRange,
});

export const DELETE_BENEFIT_CATEGORY_FILTER_REQUESTED = 'DELETE_BENEFIT_CATEGORY_FILTER_REQUESTED';
export type DeleteBenefitCategoryFilterRequestAction = Action<
    typeof DELETE_BENEFIT_CATEGORY_FILTER_REQUESTED,
    EditFilterRequest
>;
export const deleteBenefitCategoryFilterRequest = (
    filterData: EditFilterRequest,
): DeleteBenefitCategoryFilterRequestAction => ({
    type: DELETE_BENEFIT_CATEGORY_FILTER_REQUESTED,
    payload: filterData,
});

export const DELETE_BENEFIT_CATEGORY_FILTER_SUCCEED = 'DELETE_BENEFIT_CATEGORY_FILTER_SUCCEED';
export type DeleteBenefitCategoryFilterSuccessAction = Action<
    typeof DELETE_BENEFIT_CATEGORY_FILTER_SUCCEED,
    Filters,
    string
>;
export const deleteBenefitCategoryFilterSuccess = (
    filterData: Filters,
): DeleteBenefitCategoryFilterSuccessAction => ({
    type: DELETE_BENEFIT_CATEGORY_FILTER_SUCCEED,
    payload: filterData,
    meta: 'Benefit category deleted',
});

export const DELETE_BENEFIT_CATEGORY_FILTER_FAILED = 'DELETE_BENEFIT_CATEGORY_FILTER_FAILED';
export type DeleteBenefitCategoryFilterFailureAction = Action<
    typeof DELETE_BENEFIT_CATEGORY_FILTER_FAILED,
    string
>;
export const deleteBenefitCategoryFilterFailure = (
    payload: string,
): DeleteBenefitCategoryFilterFailureAction => ({
    type: DELETE_BENEFIT_CATEGORY_FILTER_FAILED,
    payload,
});

export const GET_BENEFIT_NOTES_REQUESTED = 'GET_BENEFIT_NOTES_REQUESTED';
export type GetBenefitNotesRequestAction = Action<
    typeof GET_BENEFIT_NOTES_REQUESTED,
    GetBenefitNoteRequest
>;
export const getBenefitNotesRequest = (
    data: GetBenefitNoteRequest,
): GetBenefitNotesRequestAction => ({
    type: GET_BENEFIT_NOTES_REQUESTED,
    payload: data,
});

export const GET_BENEFIT_NOTES_SUCCEED = 'GET_BENEFIT_NOTES_SUCCEED';
export type GetBenefitNotesSuccessAction = Action<
    typeof GET_BENEFIT_NOTES_SUCCEED,
    { benefitNotes: BenefitNoteData[] }
>;
export const getBenefitNotesSuccess = (
    benefitNotes: BenefitNoteData[],
): GetBenefitNotesSuccessAction => ({
    type: GET_BENEFIT_NOTES_SUCCEED,
    payload: { benefitNotes },
});

export const GET_BENEFIT_NOTES_FAILED = 'GET_BENEFIT_NOTES_FAILED';
export type GetBenefitNotesFailureAction = Action<typeof GET_BENEFIT_NOTES_FAILED, string>;
export const getBenefitNotesFailure = (payload: string): GetBenefitNotesFailureAction => ({
    type: GET_BENEFIT_NOTES_FAILED,
    payload,
});

export const CREATE_BENEFIT_NOTE_REQUESTED = 'CREATE_BENEFIT_NOTE_REQUESTED';
export type CreateBenefitNoteRequestAction = Action<
    typeof CREATE_BENEFIT_NOTE_REQUESTED,
    CreateBenefitNoteRequest
>;
export const createBenefitNoteRequest = (
    benefitNoteData: CreateBenefitNoteRequest,
): CreateBenefitNoteRequestAction => ({
    type: CREATE_BENEFIT_NOTE_REQUESTED,
    payload: benefitNoteData,
});

export const CREATE_BENEFIT_NOTE_SUCCEED = 'CREATE_BENEFIT_NOTE_SUCCEED';
export type CreateBenefitNoteSuccessAction = Action<
    typeof CREATE_BENEFIT_NOTE_SUCCEED,
    GetBenefitNoteRequest
>;
export const createBenefitNoteSuccess = (
    data: GetBenefitNoteRequest,
): CreateBenefitNoteSuccessAction => ({
    type: CREATE_BENEFIT_NOTE_SUCCEED,
    payload: data,
});

export const CREATE_BENEFIT_NOTE_FAILED = 'CREATE_BENEFIT_NOTE_FAILED';
export type CreateBenefitNoteFailureAction = Action<typeof CREATE_BENEFIT_NOTE_FAILED, string>;
export const createBenefitNoteFailure = (payload: string): CreateBenefitNoteFailureAction => ({
    type: CREATE_BENEFIT_NOTE_FAILED,
    payload,
});

export const DELETE_BENEFIT_NOTE_REQUESTED = 'DELETE_BENEFIT_NOTE_REQUESTED';
export type DeleteBenefitNoteRequestAction = Action<
    typeof DELETE_BENEFIT_NOTE_REQUESTED,
    DeleteBenefitNoteRequest
>;
export const deleteBenefitNoteRequest = (
    data: DeleteBenefitNoteRequest,
): DeleteBenefitNoteRequestAction => ({
    type: DELETE_BENEFIT_NOTE_REQUESTED,
    payload: data,
});

export const DELETE_BENEFIT_NOTE_SUCCEED = 'DELETE_BENEFIT_NOTE_SUCCEED';
export type DeleteBenefitNoteSuccessAction = Action<
    typeof DELETE_BENEFIT_NOTE_SUCCEED,
    GetBenefitNoteRequest
>;
export const deleteBenefitNoteSuccess = (
    data: GetBenefitNoteRequest,
): DeleteBenefitNoteSuccessAction => ({
    type: DELETE_BENEFIT_NOTE_SUCCEED,
    payload: data,
});

export const DELETE_BENEFIT_NOTE_FAILED = 'DELETE_BENEFIT_NOTE_FAILED';
export type DeleteBenefitNoteFailureAction = Action<typeof DELETE_BENEFIT_NOTE_FAILED, string>;
export const deleteBenefitNoteFailure = (payload: string): DeleteBenefitNoteFailureAction => ({
    type: DELETE_BENEFIT_NOTE_FAILED,
    payload,
});

export const OPEN_FORM = 'OPEN_FORM';
export type OpenFormAction = Action<typeof OPEN_FORM>;
export const openForm = (): OpenFormAction => ({ type: OPEN_FORM });

export const CLOSE_FORM = 'CLOSE_FORM';
export type CloseFormAction = Action<typeof CLOSE_FORM>;
export const closeForm = (): CloseFormAction => ({ type: CLOSE_FORM });

export const EDIT_BENEFIT_NOTE_REQUESTED = 'EDIT_BENEFIT_NOTE_REQUESTED';
export type EditBenefitNoteRequestAction = Action<
    typeof EDIT_BENEFIT_NOTE_REQUESTED,
    EditBenefitNoteRequest
>;
export const editBenefitNoteRequest = (
    benefitNoteData: EditBenefitNoteRequest,
): EditBenefitNoteRequestAction => ({
    type: EDIT_BENEFIT_NOTE_REQUESTED,
    payload: benefitNoteData,
});

export const EDIT_BENEFIT_NOTE_SUCCEED = 'EDIT_BENEFIT_NOTE_SUCCEED';
export type EditBenefitNoteSuccessAction = Action<
    typeof EDIT_BENEFIT_NOTE_SUCCEED,
    GetBenefitNoteRequest
>;
export const editBenefitNoteSuccess = (
    data: GetBenefitNoteRequest,
): EditBenefitNoteSuccessAction => ({
    type: EDIT_BENEFIT_NOTE_SUCCEED,
    payload: data,
});

export const EDIT_BENEFIT_NOTE_FAILED = 'EDIT_BENEFIT_NOTE_FAILED';
export type EditBenefitNoteFailureAction = Action<typeof EDIT_BENEFIT_NOTE_FAILED, string>;
export const editBenefitNoteFailure = (payload: string): EditBenefitNoteFailureAction => ({
    type: EDIT_BENEFIT_NOTE_FAILED,
    payload,
});

export const DELETE_BENEFIT_NOTE_FILE_REQUESTED = 'DELETE_BENEFIT_NOTE_FILE_REQUESTED';
export type DeleteBenefitNoteFileRequestAction = Action<
    typeof DELETE_BENEFIT_NOTE_FILE_REQUESTED,
    DeleteBenefitNoteFileRequest
>;
export const deleteBenefitNoteFileRequest = (
    data: DeleteBenefitNoteFileRequest,
): DeleteBenefitNoteFileRequestAction => ({
    type: DELETE_BENEFIT_NOTE_FILE_REQUESTED,
    payload: data,
});

export const DELETE_BENEFIT_NOTE_FILE_SUCCEED = 'DELETE_BENEFIT_NOTE_FILE_SUCCEED';
export type DeleteBenefitNoteFileSuccessAction = Action<
    typeof DELETE_BENEFIT_NOTE_FILE_SUCCEED,
    GetBenefitNoteRequest,
    string
>;
export const deleteBenefitNoteFileSuccess = (
    data: GetBenefitNoteRequest,
): DeleteBenefitNoteFileSuccessAction => ({
    type: DELETE_BENEFIT_NOTE_FILE_SUCCEED,
    payload: data,
    meta: 'File deleted',
});

export const DELETE_BENEFIT_NOTE_FILE_FAILED = 'DELETE_BENEFIT_NOTE_FILE_FAILED';
export type DeleteBenefitNoteFileFailureAction = Action<
    typeof DELETE_BENEFIT_NOTE_FILE_FAILED,
    string
>;
export const deleteBenefitNoteFileFailure = (
    payload: string,
): DeleteBenefitNoteFileFailureAction => ({
    type: DELETE_BENEFIT_NOTE_FILE_FAILED,
    payload,
});

export const ADD_BENEFIT_NOTE_FILE_REQUESTED = 'ADD_BENEFIT_NOTE_FILE_REQUESTED';
export type AddBenefitNoteFileRequestAction = Action<
    typeof ADD_BENEFIT_NOTE_FILE_REQUESTED,
    AddBenefitNoteFileRequest
>;
export const addBenefitNoteFileRequest = (
    data: AddBenefitNoteFileRequest,
): AddBenefitNoteFileRequestAction => ({
    type: ADD_BENEFIT_NOTE_FILE_REQUESTED,
    payload: data,
});

export const ADD_BENEFIT_NOTE_FILE_SUCCEED = 'ADD_BENEFIT_NOTE_FILE_SUCCEED';
export type AddBenefitNoteFileSuccessAction = Action<
    typeof ADD_BENEFIT_NOTE_FILE_SUCCEED,
    GetBenefitNoteRequest,
    string
>;
export const addBenefitNoteFileSuccess = (
    data: GetBenefitNoteRequest,
): AddBenefitNoteFileSuccessAction => ({
    type: ADD_BENEFIT_NOTE_FILE_SUCCEED,
    payload: data,
    meta: 'File added',
});

export const ADD_BENEFIT_NOTE_FILE_FAILED = 'ADD_BENEFIT_NOTE_FILE_FAILED';
export type AddBenefitNoteFileFailureAction = Action<typeof ADD_BENEFIT_NOTE_FILE_FAILED, string>;
export const addBenefitNoteFileFailure = (payload: string): AddBenefitNoteFileFailureAction => ({
    type: ADD_BENEFIT_NOTE_FILE_FAILED,
    payload,
});

export const TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_REQUESTED =
    'TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_REQUESTED';
export type ToggleMultipleInvitePendingExecutiveUsersRoleRequestAction = Action<
    typeof TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_REQUESTED,
    ToggleMultipleInvitePendingExecutiveUsersRoleRequest
>;
export const toggleMultipleInvitePendingExecutiveUsersRoleRequest = (
    assignMultiple: ToggleMultipleInvitePendingExecutiveUsersRoleRequest,
): ToggleMultipleInvitePendingExecutiveUsersRoleRequestAction => ({
    type: TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_REQUESTED,
    payload: assignMultiple,
});

export const TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_SUCCEED =
    'TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_SUCCEED';
export type ToggleMultipleInvitePendingExecutiveUsersRoleSuccessAction = Action<
    typeof TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_SUCCEED,
    null,
    string
>;

export const toggleMultipleInvitePendingExecutiveUsersRoleSuccess = (
    meta: string,
): ToggleMultipleInvitePendingExecutiveUsersRoleSuccessAction => ({
    type: TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_SUCCEED,
    meta,
});

export const TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_FAILED =
    'TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_FAILED';
export type ToggleMultipleInvitePendingExecutiveUsersRoleFailureAction = Action<
    typeof TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_FAILED,
    string,
    null
>;
export const toggleMultipleInvitePendingExecutiveUsersRoleFailure = (
    payload: string,
): ToggleMultipleInvitePendingExecutiveUsersRoleFailureAction => ({
    type: TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_FAILED,
    payload,
});

export const GET_BENEFITS_WITH_SUBMITTERS_REQUESTED = 'GET_BENEFITS_WITH_SUBMITTERS_REQUESTED';
export type GetBenefitsWithSubmittersRequestAction = Action<
    typeof GET_BENEFITS_WITH_SUBMITTERS_REQUESTED
>;
export const getBenefitsWithSubmittersRequest = (): GetBenefitsWithSubmittersRequestAction => ({
    type: GET_BENEFITS_WITH_SUBMITTERS_REQUESTED,
});

export const GET_BENEFITS_WITH_SUBMITTERS_SUCCEED = 'GET_BENEFITS_WITH_SUBMITTERS_SUCCEED';
export type GetBenefitsWithSubmittersSuccessAction = Action<
    typeof GET_BENEFITS_WITH_SUBMITTERS_SUCCEED,
    { benefits: Benefit[] }
>;

export const getBenefitsWithSubmittersSuccess = (
    benefits: Benefit[],
): GetBenefitsWithSubmittersSuccessAction => ({
    type: GET_BENEFITS_WITH_SUBMITTERS_SUCCEED,
    payload: { benefits },
});

export const GET_BENEFITS_WITH_SUBMITTERS_FAILED = 'GET_BENEFITS_WITH_SUBMITTERS_FAILED';
export type GetBenefitsWithSubmittersFailureAction = Action<
    typeof GET_BENEFITS_WITH_SUBMITTERS_FAILED,
    string
>;
export const getBenefitsWithSubmittersFailure = (
    payload: string,
): GetBenefitsWithSubmittersFailureAction => ({
    type: GET_BENEFITS_WITH_SUBMITTERS_FAILED,
    payload,
});

export type BenefitActions =
    | AssignEmployeesRequestAction
    | AssignEmployeesSuccessAction
    | AssignEmployeesFailureAction
    | AssignEmployeesFailureAction
    | UnassignEmployeesRequestAction
    | UnassignEmployeesSuccessAction
    | UnassignEmployeesFailureAction
    | CreateBenefitRequestAction
    | CreateBenefitSuccessAction
    | CreateBenefitFailureAction
    | DeleteBenefitRequest
    | DeleteBenefitSuccessAction
    | DeleteBenefitFailureAction
    | EditBenefitRequestAction
    | EditBenefitSuccessAction
    | EditBenefitFailureAction
    | GetBenefitPriorityDashboardSuccessAction
    | FilterBenefitsRequestAction
    | FilterBenefitsSuccessAction
    | FilterBenefitsFailureAction
    | FilterContractBenefitsRequestAction
    | FilterContractBenefitsSuccessAction
    | FilterContractBenefitsFailureAction
    | ResetFilteredContractIdAction
    | GetBenefitsSuccessAction
    | GetMyBenefitsRequestAction
    | GetMyBenefitsSuccessAction
    | GetMyBenefitsFailureAction
    | GetBenefitCategoriesRequestAction
    | GetBenefitCategoriesSuccessAction
    | GetBenefitCategoriesFailureAction
    | GetBenefitsRolesRequestAction
    | GetBenefitsRolesSuccessAction
    | GetBenefitsRolesFailureAction
    | GetContractBenefitsRequestAction
    | GetContractBenefitsSuccessAction
    | GetContractBenefitsFailureAction
    | GetContractBenefitDataRequestAction
    | GetContractBenefitDataSuccessAction
    | GetContractBenefitDataFailureAction
    | AddBenefitRoleRequestAction
    | AddBenefitRoleSuccessAction
    | AddBenefitRoleFailureAction
    | DeleteBenefitRoleRequestAction
    | DeleteBenefitRoleSuccessAction
    | DeleteBenefitRoleFailureAction
    | ArchiveBenefitCategoryRequestAction
    | ArchiveBenefitCategorySuccessAction
    | ArchiveBenefitCategoryFailureAction
    | ChangeBenefitStatusRequestAction
    | ChangeBenefitStatusSuccessAction
    | ChangeBenefitStatusFailureAction
    | ToggleMultipleEmployeesRoleRequestAction
    | ToggleMultipleEmployeesRoleSuccessAction
    | ToggleMultipleEmployeesRoleFailureAction
    | SelectBenefitContractAction
    | GetBenefitPrioritiesRequestAction
    | GetBenefitPrioritiesSuccessAction
    | GetBenefitPrioritiesFailureAction
    | EditBenefitPriorityRequestAction
    | EditBenefitPrioritySuccessAction
    | EditBenefitPriorityFailureAction
    | AddBenefitPriorityRequestAction
    | AddBenefitPrioritySuccessAction
    | AddBenefitPriorityFailureAction
    | DeleteBenefitPriorityRequestAction
    | DeleteBenefitPrioritySuccessAction
    | DeleteBenefitPriorityFailureAction
    | SetDateRangeBenefitPrioritiesDashboardAction
    | DeleteBenefitCategoryFilterRequestAction
    | DeleteBenefitCategoryFilterSuccessAction
    | DeleteBenefitCategoryFilterFailureAction
    | GetBenefitNotesRequestAction
    | GetBenefitNotesSuccessAction
    | GetBenefitNotesFailureAction
    | CreateBenefitNoteRequestAction
    | CreateBenefitNoteSuccessAction
    | CreateBenefitNoteFailureAction
    | DeleteBenefitNoteRequestAction
    | DeleteBenefitNoteSuccessAction
    | DeleteBenefitNoteFailureAction
    | OpenFormAction
    | CloseFormAction
    | EditBenefitNoteRequestAction
    | EditBenefitNoteSuccessAction
    | EditBenefitNoteFailureAction
    | DeleteBenefitNoteFileRequestAction
    | DeleteBenefitNoteFileSuccessAction
    | DeleteBenefitNoteFileFailureAction
    | AddBenefitNoteFileRequestAction
    | AddBenefitNoteFileSuccessAction
    | AddBenefitNoteFileFailureAction
    | ToggleMultipleInvitePendingExecutiveUsersRoleRequestAction
    | ToggleMultipleInvitePendingExecutiveUsersRoleSuccessAction
    | ToggleMultipleInvitePendingExecutiveUsersRoleFailureAction
    | GetBenefitsWithSubmittersRequestAction
    | GetBenefitsWithSubmittersSuccessAction
    | GetBenefitsWithSubmittersFailureAction;
