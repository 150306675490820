import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ContractWithRAG } from 'features/contract/models/contractModels';
import { Column, PaginationResources } from '../models/paginationModel';
import { getStatusLabel } from 'common/helpers/utils';
import { EditPaths } from 'features/edit/models/editModel';

interface ExpandableRowProps {
    rowData: ContractWithRAG;
    columns: Column<PaginationResources>[];
}

const RowWrapper = styled.div`
    display: flex;
    margin-bottom: 1%;
    margin-left: 8%;
`;

const ColumnTitle = styled.p`
    margin-right: 10%;
    width: 18%;
`;

const ColumnContent = styled.p`
    margin: 0;
`;

const getLinkForDisplay = (key: string, rowData: ContractWithRAG): string | null => {
    const { contract, id } = rowData;

    switch (key) {
        case 'title':
            return id ? `${EditPaths.Contract}/${id}/project-details` : '';
        case 'contractTitle':
            return contract ? `${EditPaths.Contract}/${contract}/project-details` : '';
        case 'outcome':
            return contract && id ? `/edit/project/${contract}/benefits/${id}/benefit-details` : '';
        default:
            return '';
    }
};

const renderColumnContent = (key: string, rowData: ContractWithRAG) => {
    const link = getLinkForDisplay(key, rowData);
    if (link) {
        return <Link to={link}>{rowData[key]}</Link>;
    }

    return getStatusLabel(rowData[key]);
};

export const ExpandableRow: React.FunctionComponent<ExpandableRowProps> = ({
    rowData,
    columns,
}) => (
    <>
        {columns
            .filter((column) => column.key !== 'id')
            .map(({ key, title }) => (
                <RowWrapper key={key}>
                    <ColumnTitle>{title}</ColumnTitle>
                    <ColumnContent>{renderColumnContent(key, rowData)}</ColumnContent>
                </RowWrapper>
            ))}
    </>
);
