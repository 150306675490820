import * as React from 'react';

import { SubmitButton } from 'common/components';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { EmployeeRole } from 'features/edit/components/EmployeeRole';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { notArchivedFilterParam } from 'config/config';
import {
    Antd3Form,
    StyledAntd3Form,
    Antd3FormProps,
    Antd3Icon,
} from 'common/components/deprecated/antd3';
import { Input, PhoneInput, ResetButton } from 'common/components/deprecated/form';

import { InviteByEmail } from '../models/inviteModel';
import { PendingInvitationsList } from './PendingInvitationsList';
import styled from 'styled-components';

export interface InviteEmployeeProps {
    isFetching: boolean;
    inviteEmployeeRequest(employee: InviteByEmail): void;
    redirectToUrl(path: string): void;
}

const warningMessages = [
    'Please make sure you use this form to only invite colleagues from your Buyer organisation who should have full access to your organisation’s data.',
    'If you would like to invite Supplier or Delivery Partner users, use the “Invite new user” form from the Supplier or Delivery Partner section on the  “Create Benefit” or “Edit Benefit” views.',
    'Also, please make sure you only apply Admin permissions to your colleagues when you would like them to help you administer your account, change settings, and create Projects.',
];

export const WarningMessageWrapper = styled.p`
    width: 100%;
    padding-inline: calc((100% - 90%) / 2);
    align-items: baseline;
    color: #faad14;
    cursor: pointer;
    font-size: medium;
    line-height: 1.5;
`;

type Props = InviteEmployeeProps & Antd3FormProps;

class InviteEmployeeComponent extends React.Component<Props> {
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, { email, ...values }) =>
            error
                ? showNotification({ text: notificationText.Error })
                : this.props.inviteEmployeeRequest({ email: email.toLowerCase(), ...values }),
        );
    };

    public render(): JSX.Element {
        const { form, isFetching } = this.props;

        return (
            <>
                <StyledAntd3Form onSubmit={this.handleSubmit} header="Invite Employee" bigHeader>
                    {warningMessages.map((message) => (
                        <WarningMessageWrapper key={message}>
                            {' '}
                            <Antd3Icon type="exclamation-circle" style={{ marginRight: '5px' }} />
                            {message}
                        </WarningMessageWrapper>
                    ))}

                    <ResetButton form={form} />
                    <Input form={form} id="firstName" placeholder="First Name" required />
                    <Input form={form} id="lastName" placeholder="Last Name" required />
                    <Input
                        form={form}
                        id="email"
                        type="email"
                        placeholder="Email Address"
                        required={true}
                    />
                    <PhoneInput
                        form={form}
                        id="telephone"
                        placeholder="Telephone Number"
                        required={true}
                    />
                    <AutocompleteSelect
                        autocompleteField={AutocompleteField.Department}
                        form={form}
                        id="department"
                        placeholder="Department"
                        notFoundContent="No departments found"
                        searchParams={notArchivedFilterParam}
                        required
                    />
                    <EmployeeRole form={form} />
                    <SubmitButton isFetching={isFetching} value="Invite Employee" />
                </StyledAntd3Form>
                <PendingInvitationsList {...this.props} />
            </>
        );
    }
}

export const InviteEmployee = Antd3Form.create({})(InviteEmployeeComponent);
