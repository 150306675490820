import { Button, Form, Input, List, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PaddedSection } from 'common/components';
import { Props } from '../containers/SubscriptionContainer';
import { getCurrentUserOrganisation } from '../selectors/userSelectors';
import { ButtonWrapper } from 'common/components/styled';
import { Antd3Icon } from 'common/components/deprecated/antd3';
import { openModal } from 'common/helpers/modal';
import { FormEvent } from 'react';

import { PurchaseSubscriptionRequest } from '../actions/userActions';
import moment from 'moment';
import {
    isWithinOneMonthBeforeExpiry,
    getSubscriptionStatusIndicator,
    wordCapitaliser,
    formatDisplayTimestamp,
} from 'common/helpers/utils';

export const SubscriptionUserInfo = ({ userSubscription, profile }: Props) => {
    const activeOrganisation = useSelector(getCurrentUserOrganisation);
    const dispatch = useDispatch();
    const { itemTitle, tierIndex, type, paymentPeriods, createdAt, activatedAt, expiresAt } =
        userSubscription;

    const openCancelSubscriptionModal = (e: FormEvent) => {
        e.preventDefault();

        return openModal({
            okText: 'Cancel Subscription',
            cancelText: 'Cancel',
            callback: () => {
                dispatch({
                    type: 'CANCEL_SUBSCRIPTION_REQUEST',
                    payload: userSubscription.id,
                });
            },
            title: 'Warning: This action will cancel subscription, do you want to proceed?',
        });
    };
    const renewSubscription = () => {
        dispatch(
            PurchaseSubscriptionRequest({
                item: tierIndex,
                payment_periods: paymentPeriods,
                starting_on: moment(expiresAt).format('YYYY-MM-DD'),
                type: wordCapitaliser(type),
            }),
        );
    };

    return (
        <>
            <PaddedSection header="Subscription">
                <List>
                    <List.Item>Type: {itemTitle}</List.Item>
                    <List.Item>Payment Type: {type}</List.Item>
                    <List.Item>
                        Status:
                        <span
                            style={{
                                color: getSubscriptionStatusIndicator(expiresAt?.slice(0, 10))
                                    .color,
                            }}
                        >
                            {getSubscriptionStatusIndicator(expiresAt).status}
                        </span>
                    </List.Item>
                    <List.Item>Number of Payment Periods: {paymentPeriods}</List.Item>
                    <List.Item>Created at: {formatDisplayTimestamp(createdAt)}</List.Item>{' '}
                    <List.Item>Started at: {formatDisplayTimestamp(activatedAt)}</List.Item>
                    <List.Item>Expires at: {formatDisplayTimestamp(expiresAt)}</List.Item>
                    <List.Item>
                        Number of active users:
                        <span>
                            {` ${activeOrganisation?.activeUsersCount}/${activeOrganisation?.maxAvailableNumberOfUsers}`}
                        </span>
                    </List.Item>
                    <List.Item>
                        Number of available admins:
                        <span>{activeOrganisation?.availableAdministratorRoles ?? '–'}</span>
                    </List.Item>
                </List>
                <ButtonWrapper>
                    <Button
                        danger
                        onClick={(e: FormEvent) => {
                            openCancelSubscriptionModal(e);
                        }}
                    >
                        Cancel Subscription
                        <Antd3Icon type="cross" />
                    </Button>
                    {isWithinOneMonthBeforeExpiry(expiresAt) && (
                        <Button type="primary" onClick={renewSubscription}>
                            Renew Subscription
                            <Antd3Icon type="dollar" />
                        </Button>
                    )}
                </ButtonWrapper>
            </PaddedSection>
        </>
    );
};
