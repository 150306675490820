import * as React from 'react';
import { Button, Input } from 'antd';

import { ButtonWrapper } from 'common/components/styled';
import { EditEmployeeProfile } from 'features/user/models/userModel';
import { SupplierUserForm } from 'features/edit/components/SupplierUserForm';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { generateColumns } from 'common/helpers/utils';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { getEmployeesSuccess } from 'features/user/actions/userActions';
import { openModal } from 'common/helpers/modal';
import { DrawerContainer } from 'common/components/DrawerContainer';
import { Antd3Form, Antd3Icon, Antd3FormProps } from 'common/components/deprecated/antd3';

import { SupplierEditUsersContainerProps } from '../containers/SupplierEditUsersContainer';
import { SupplierEditPaths } from '../models/editModel';
import { PaddedSection } from 'common/components';

interface SupplierEditUsersState {
    editingUserProfile?: EditEmployeeProfile;
}

type SupplierEditUsersProps = Antd3FormProps & SupplierEditUsersContainerProps;

const editEmployeesColumns = (
    openArchiveModal: (e: React.FormEvent, id: number, name: string, archive: boolean) => void,
    handleEditButtonClick: (e: React.FormEvent, id: number) => void,
) => [
    {
        title: 'Employee',
        key: 'id',
        render: (
            _: string,
            { user: { firstName, lastName }, isAdministrator }: EditEmployeeProfile,
        ) => `${firstName} ${lastName} ${isAdministrator ? '(Administrator)' : ''}`,
    },
    {
        title: '',
        key: 'user.id',
        render: (
            _: string,
            { id, archived, user: { firstName, lastName } }: EditEmployeeProfile,
        ) => {
            const archiveBtnText = archived ? 'Unarchive' : 'Archive';
            return (
                <ButtonWrapper>
                    <Button
                        icon={<Antd3Icon type="edit" />}
                        size="small"
                        onClick={(e: React.FormEvent) => handleEditButtonClick(e, id)}
                    >
                        Edit
                    </Button>
                    <Button
                        onClick={(e: React.FormEvent) =>
                            openArchiveModal(e, id, `${firstName} ${lastName}`, !archived)
                        }
                        type={archived ? 'default' : 'danger'}
                        icon={<Antd3Icon type={archived ? 'undo' : 'folder'} />}
                        size="small"
                    >
                        {archiveBtnText}
                    </Button>
                </ButtonWrapper>
            );
        },
        width: 300,
    },
];

class SupplierEditUsersForm extends React.Component<
    SupplierEditUsersProps,
    SupplierEditUsersState
> {
    constructor(props: SupplierEditUsersProps) {
        super(props);
        this.state = {
            editingUserProfile: {},
        };
        this.handleRolechange = this.handleRolechange.bind(this);
    }
    private handleRolechange(isAdmin: boolean): void {
        this.setState((prevState) => ({
            editingUserProfile: {
                ...prevState.editingUserProfile,
                isAdministrator: isAdmin,
            },
        }));
    }
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const { editingUserProfile } = this.state;
        event.preventDefault();

        this.props.form.validateFields((error, { firstName, lastName, ...values }) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }

            return this.props.editEmployeeRequest({
                id: editingUserProfile && editingUserProfile.id,
                user: {
                    firstName,
                    lastName,
                },
                isAdministrator: editingUserProfile && editingUserProfile.isAdministrator,
                ...values,
            });
        });
    };

    private handleEditButtonClick = (e: React.FormEvent, id: number) => {
        e.preventDefault();

        this.setState({
            editingUserProfile: this.props.employees.find((employee) => employee.id === id),
        });

        this.props.openForm();
    };

    private archiveEmployee = (e: React.FormEvent, id: number, name: string, archived: boolean) => {
        e.preventDefault();

        openModal({
            title: `${name}: Are you sure you want to ${
                archived ? 'archive' : 'restore'
            } this employee?`,
            callback: () => this.props.archiveEmployeeRequest(id, archived),
        });
    };

    private searchEmployees = (event: React.FormEvent<HTMLFormElement>) => {
        this.props.saveFilters({
            paginatedView: PaginationView.EditEmployees,
            values: {
                fullName: event.currentTarget.value,
            },
        });

        this.props.paginationRequest({
            view: PaginationView.EditEmployees,
            pagination: {
                current: 1,
            },
            paginationSuccessCallback: getEmployeesSuccess,
        });
    };
    public componentWillUnmount = () => this.props.clearFilters(PaginationView.EditEmployees);

    public render(): JSX.Element {
        const { editingUserProfile } = this.state;

        return (
            <PaddedSection header="Edit Employees" bigHeader>
                <Antd3Form.Item>
                    <Input.Search placeholder="Search employees" onChange={this.searchEmployees} />
                    <PaginatedTable
                        view={PaginationView.EditEmployees}
                        data={this.props.employees}
                        paginationSuccessCallback={getEmployeesSuccess}
                        columns={generateColumns(
                            editEmployeesColumns(this.archiveEmployee, this.handleEditButtonClick),
                        )}
                        emptyText="No employees found"
                        pageNumber={this.props.pageNumber}
                        pathUrl={SupplierEditPaths.Users}
                        withPaginationHistory
                    />
                </Antd3Form.Item>
                {editingUserProfile && (
                    <DrawerContainer
                        title="Edit employee"
                        onClose={this.props.closeForm}
                        visible={this.props.formVisible}
                    >
                        <Antd3Form onSubmit={this.handleSubmit}>
                            <SupplierUserForm
                                profile={editingUserProfile}
                                organisation={this.props.organisation}
                                showInviteButton={false}
                                showSaveButton={true}
                                form={this.props.form}
                                isFetching={this.props.isFetching}
                                handleRoleChange={this.handleRolechange}
                            />
                        </Antd3Form>
                    </DrawerContainer>
                )}
            </PaddedSection>
        );
    }
}

export const SupplierEditUsers = Antd3Form.create({})(SupplierEditUsersForm);
