import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { FullPageSpinner } from 'common/components';
import { Organisation } from 'features/organisation/models/organisationModels';
import { Profile, Subscription } from 'features/user/models/userModel';
import {
    getAllEmployees,
    getFetchingUserStatus,
    getUserOrganisationId,
    getEmployeeFormVisibility,
    getOrganisationCurrentSubscription,
    getCurrentUserOrganisation,
} from 'features/user/selectors/userSelectors';
import {
    archiveEmployeeRequest,
    editEmployeeRequest,
    openForm,
    closeForm,
} from 'features/user/actions/userActions';
import { editOrganisationNameRequest } from 'features/organisation/actions/organisationActions';
import { getFetchingOrganisationsStatus } from 'features/organisation/selectors/organisationSelectors';
import { inviteEmployeeRequest } from 'features/invite/actions/inviteActions';
import { redirectToUrl } from 'common/actions/navigationAction';
import {
    paginationRequest,
    saveFilters,
    clearFilters,
} from 'features/pagination/actions/paginationActions';
import { AppState } from 'common/appState';
import { RouteParams, getPageNumber } from 'common/selectors/routeSelectors';
import { Antd3Form } from 'common/components/deprecated/antd3';
import { colors, fontWeights } from 'theme/variables';
import { Tabs } from 'common/components/styled';

import { SupplierEditUsers as SupplierEditUsersComponent } from '../components/SupplierEditUsers';
import { SupplierEditOrganisation } from '../components/SupplierEditOrganisation';
import { SubscriptionContainer } from 'features/user/containers/SubscriptionContainer';
import { getViewSupplierSubscriptionView } from '../selectors/editSelector';
import { OpenSupplierSubscriptionViewRequest } from '../actions/editActions';

const ListWrapper = styled(Antd3Form.Item)`
    width: 100%;
    background-color: ${colors.primaryWhite};
    .ant-list-header {
        background-color: ${colors.primary};
        color: ${colors.primaryWhite};
        font-weight: ${fontWeights.bold};
    }
`;

export interface SupplierEditUsersStateProps {
    employees: Profile[];
    organisationId: number;
    organisation: Organisation[];
    isFetching: boolean;
    isFetchingOrganisations: boolean;
    formVisible: boolean;
    pageNumber?: number;
    hasRedirectedToSupplierSubscriptionView?: boolean;
    subscription: Subscription | undefined;
}

interface SupplierEditUsersContainerDispatchProps {
    archiveEmployeeRequest: typeof archiveEmployeeRequest;
    editEmployeeRequest: typeof editEmployeeRequest;
    editOrganisationNameRequest: typeof editOrganisationNameRequest;
    inviteEmployeeRequest: typeof inviteEmployeeRequest;
    redirectToUrl: typeof redirectToUrl;
    paginationRequest: typeof paginationRequest;
    saveFilters: typeof saveFilters;
    clearFilters: typeof clearFilters;
    openForm: typeof openForm;
    closeForm: typeof closeForm;
    history: any;
}

export type SupplierEditUsersContainerProps = SupplierEditUsersStateProps &
    SupplierEditUsersContainerDispatchProps;

class SupplierEditUsers extends React.Component<SupplierEditUsersContainerProps> {
    constructor(props: SupplierEditUsersContainerProps) {
        super(props);
        this.state = {
            activeKey: 'edit-employees',
        };
    }

    public render(): JSX.Element {
        const { activeKey } = this.state;

        if (this.props.subscription?.status === 'EXPIRED') {
            return (
                <ListWrapper>
                    <Tabs noBottomMargin defaultActiveKey={'edit-subscriptions'}>
                        <Tabs.TabPane tab="Subscriptions" key="edit-subscriptions">
                            <SubscriptionContainer />
                        </Tabs.TabPane>
                    </Tabs>
                </ListWrapper>
            );
        }

        return this.props.isFetchingOrganisations ? (
            <FullPageSpinner />
        ) : (
            <ListWrapper>
                <Tabs noBottomMargin defaultActiveKey={activeKey}>
                    <>
                        <Tabs.TabPane tab="Edit Employees" key="edit-employees">
                            <SupplierEditUsersComponent {...this.props} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Edit Organisation" key="edit-organisation">
                            <SupplierEditOrganisation {...this.props} />
                        </Tabs.TabPane>
                    </>
                    <Tabs.TabPane tab="Subscriptions" key="edit-subscriptions">
                        <SubscriptionContainer />
                    </Tabs.TabPane>
                </Tabs>
            </ListWrapper>
        );
    }
}

const mapStateToProps = createStructuredSelector<
    AppState,
    RouteParams,
    SupplierEditUsersStateProps
>({
    employees: getAllEmployees,
    subscription: getOrganisationCurrentSubscription,
    organisationId: getUserOrganisationId,
    organisation: getCurrentUserOrganisation,
    isFetching: getFetchingUserStatus,
    isFetchingOrganisations: getFetchingOrganisationsStatus,
    formVisible: getEmployeeFormVisibility,
    pageNumber: (_, ownProps) => getPageNumber(ownProps),
    hasRedirectedToSupplierSubscriptionView: getViewSupplierSubscriptionView,
});

export const SupplierEditUsersContainer = connect(mapStateToProps, {
    archiveEmployeeRequest,
    editEmployeeRequest,
    editOrganisationNameRequest,
    inviteEmployeeRequest,
    redirectToUrl,
    paginationRequest,
    saveFilters,
    clearFilters,
    openForm,
    closeForm,
})(SupplierEditUsers);
