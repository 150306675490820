import posthog from 'posthog-js';
import { Action } from 'common/actions/appAction';
import { SENTRY_ENVIRONMENT } from 'config/env';

import { DownloadRequest, InvoiceDownloadRequest } from '../models/downloadModel';

export const GET_BENEFITS_CSV_REQUESTED = 'GET_BENEFITS_CSV_REQUESTED';
export type GetBenefitsCSVRequestAction = Action<
    typeof GET_BENEFITS_CSV_REQUESTED,
    DownloadRequest
>;
export const getBenefitsCSVRequest = (
    downloadRequest: DownloadRequest,
): GetBenefitsCSVRequestAction => ({
    type: GET_BENEFITS_CSV_REQUESTED,
    payload: downloadRequest,
});

export const GET_BENEFITS_CSV_SUCCEED = 'GET_BENEFITS_CSV_SUCCEED';
export type GetBenefitsCSVSuccessAction = Action<
    typeof GET_BENEFITS_CSV_SUCCEED,
    null,
    string,
    posthog.CaptureResult | null
>;
export const getBenefitsCSVSuccess = (): GetBenefitsCSVSuccessAction => ({
    type: GET_BENEFITS_CSV_SUCCEED,
    meta: 'Benefits exported',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production' ? posthog.capture('Benefits exported') : null,
});

export const GET_BENEFITS_CSV_FAILED = 'GET_BENEFITS_CSV_FAILED';
export type GetBenefitsCSVFailureAction = Action<
    typeof GET_BENEFITS_CSV_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const getBenefitsCSVFailure = (payload: string): GetBenefitsCSVFailureAction => ({
    type: GET_BENEFITS_CSV_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Benefits export failed')
            : null,
});

export const GET_CONTRACTS_CSV_REQUESTED = 'GET_CONTRACTS_CSV_REQUESTED';
export type GetContractsCSVRequestAction = Action<
    typeof GET_CONTRACTS_CSV_REQUESTED,
    DownloadRequest
>;
export const getContractsCSVRequest = (
    downloadRequest: DownloadRequest,
): GetContractsCSVRequestAction => ({
    type: GET_CONTRACTS_CSV_REQUESTED,
    payload: downloadRequest,
});

export const GET_CONTRACTS_CSV_SUCCEED = 'GET_CONTRACTS_CSV_SUCCEED';
export type GetContractsCSVSuccessAction = Action<
    typeof GET_CONTRACTS_CSV_SUCCEED,
    null,
    string,
    posthog.CaptureResult | null
>;
export const getContractsCSVSuccess = (): GetContractsCSVSuccessAction => ({
    type: GET_CONTRACTS_CSV_SUCCEED,
    meta: 'Projects exported',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production' ? posthog.capture('Contracts exported') : null,
});

export const DELAYED_CSV_DOWNLOAD = 'DELAYED_CSV_DOWNLOAD';
export type DelayedCSVDownloadAction = Action<typeof DELAYED_CSV_DOWNLOAD, null, string>;
export const delayedCSVDownload = (): DelayedCSVDownloadAction => ({
    type: DELAYED_CSV_DOWNLOAD,
    meta: 'Delayed download',
});

export const GET_CONTRACTS_CSV_FAILED = 'GET_CONTRACTS_CSV_FAILED';
export type GetContractsCSVFailureAction = Action<
    typeof GET_CONTRACTS_CSV_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const getContractsCSVFailure = (payload: string): GetContractsCSVFailureAction => ({
    type: GET_CONTRACTS_CSV_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Projects export failed')
            : null,
});

export const DOWNLOAD_INVOICE_PDF_REQUESTED = 'DOWNLOAD_INVOICE_PDF_REQUESTED';
export type DownloadInvoiceFileRequestAction = Action<
    typeof DOWNLOAD_INVOICE_PDF_REQUESTED,
    InvoiceDownloadRequest
>;
export const downloadInvoicePdfRequest = (
    payload: InvoiceDownloadRequest,
): DownloadInvoiceFileRequestAction => ({
    type: DOWNLOAD_INVOICE_PDF_REQUESTED,
    payload,
});

export const DOWNLOAD_INVOICE_PDF_SUCCEED = 'DOWNLOAD_INVOICE_PDF_SUCCEED';
export type DownloadInvoicePdfSuccessAction = Action<
    typeof DOWNLOAD_INVOICE_PDF_SUCCEED,
    string,
    string,
    posthog.CaptureResult | null
>;
export const downloadInvoicePdfSuccess = (payload: string): DownloadInvoicePdfSuccessAction => ({
    type: DOWNLOAD_INVOICE_PDF_SUCCEED,
    payload,
    meta: 'Invoice downloaded',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production' ? posthog.capture('Contracts exported') : null,
});
export const DOWNLOAD_INVOICE_PDF_FAILED = 'DOWNLOAD_INVOICE_PDF_FAILED';
export type DownloadInvoicePdfFailureAction = Action<
    typeof DOWNLOAD_INVOICE_PDF_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const downloadInvoicePdfFailure = (payload: string): DownloadInvoicePdfFailureAction => ({
    type: DOWNLOAD_INVOICE_PDF_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Projects export failed')
            : null,
});

export const CLEAR_INVOICE_URL = 'CLEAR_INVOICE_URL';
export type ClearInvoiceUrlAction = {
    type: typeof CLEAR_INVOICE_URL;
};
export const clearInvoiceUrl = (): ClearInvoiceUrlAction => ({
    type: CLEAR_INVOICE_URL,
});
export type DownloadActions =
    | GetBenefitsCSVRequestAction
    | GetBenefitsCSVSuccessAction
    | GetBenefitsCSVFailureAction
    | GetContractsCSVRequestAction
    | GetContractsCSVSuccessAction
    | GetContractsCSVFailureAction
    | DelayedCSVDownloadAction
    | DownloadInvoiceFileRequestAction
    | DownloadInvoicePdfSuccessAction
    | DownloadInvoicePdfFailureAction
    | ClearInvoiceUrlAction;
