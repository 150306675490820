import React from 'react';
import { List } from 'antd';
import styled from 'styled-components';

import { colors, fontWeights } from 'theme/variables';
import { getListData } from 'common/helpers/getListData';
import { Benefit, BenefitOrganisationRole } from 'features/benefit/models/benefitModels';
import { Contract } from 'features/contract/models/contractModels';

interface BenefitDetailsProps {
    benefit: Benefit;
    contract: Contract;
    showFinalDeliveryDate: boolean;
    benefitSuppliers: BenefitOrganisationRole[];
    benefitPartners: BenefitOrganisationRole[];
}

const ListWrapper = styled.div`
    width: 100%;
    background-color: ${colors.primaryWhite};

    .ant-list-header {
        background-color: ${colors.primary};
        color: ${colors.primaryWhite};
        font-weight: ${fontWeights.bold};
    }
`;

export const BenefitDetails: React.FunctionComponent<BenefitDetailsProps> = ({
    benefit,
    contract,
    showFinalDeliveryDate,
    benefitSuppliers,
    benefitPartners,
}) => {
    const contractTitle =
        `Project: ${contract.title}` +
        `${contract.referenceNumber ? ` | ${contract.referenceNumber}` : ``}`;

    return (
        <ListWrapper>
            <List
                header={contractTitle}
                dataSource={getListData(
                    benefit,
                    showFinalDeliveryDate,
                    benefitSuppliers,
                    benefitPartners,
                )}
                bordered
                renderItem={({ name }: { id: number; name: string }) => (
                    <List.Item>
                        <b>{name.split(':')[0] + ':'}</b> &nbsp; {name.split(':')[1]}
                    </List.Item>
                )}
            />
        </ListWrapper>
    );
};
