import { createSelector } from 'reselect';

import { AppState } from 'common/appState';
import { extractRouteBenefitId } from 'common/selectors/utilitySelectors';
import {
    ContractBenefit,
    BenefitTemplate,
    Benefit,
    mapBenefitRoleShortcuts,
    BenefitRole,
} from 'features/benefit/models/benefitModels';
import { Filters } from 'features/contract/models/contractModels';
import { getUserId, getAllEmployees } from 'features/user/selectors/userSelectors';
import { Role } from 'features/user/models/userModel';
import { getExecutives } from 'features/organisation/selectors/organisationSelectors';
import { getName } from 'features/contract/selectors/contractSelectors';
import { Organisation } from 'features/organisation/models/organisationModels';

export const getBenefitTemplate = (state: AppState) => state.benefit.benefitTemplate;
export const getBenefitTemplates = (state: AppState) => state.benefit.benefitTemplates;
export const getBenefitCategories = (state: AppState) => state.benefit.benefitCategories;
export const getBenefits = (state: AppState) => state.benefit.benefits;
export const getBenefit = (state: AppState) => {
    const benefit = state.benefit.benefit;

    return benefit?.id !== 0 ? benefit : undefined;
};
export const getMyBenefits = (state: AppState) => state.benefit.myBenefits;
export const getBenefitTemplatesWithRag = (state: AppState) =>
    state.benefit.benefitTemplatesWithRag;
export const getBenefitPriorityDashboard = (state: AppState) => state.benefit.priorityDashboard;
export const getBenefitsRoles = (state: AppState) => state.benefit.benefitRoles;
export const getContractBenefits = (state: AppState) => state.benefit.contractBenefits;
export const getCreatingBenefitStatus = (state: AppState) => state.benefit.isCreating;
export const getFetchingBenefitsStatus = (state: AppState) => state.benefit.isFetching;
export const getFilteringBenefitsStatus = (state: AppState) => state.benefit.isFiltered;
export const getFilteringContractBenefitsStatus = (state: AppState) =>
    state.benefit.contractBenefitsFiltered;
export const getFilteredContractId = (state: AppState) => state.benefit.filteredContractId;
export const getFetchingBenefitTemplatesStatus = (state: AppState) =>
    state.benefit.benefitTemplatesFetching;
export const getFetchingBenefitsCategoriesStatus = (state: AppState) =>
    state.benefit.isFetchingBenefitCategories;
export const getUpdatingBenefitStatus = (state: AppState) => state.benefit.isUpdating;
export const getFetchingRolesStatus = (state: AppState) => state.benefit.isFetchingRoles;
export const getSelectedBenefitContractId = (state: AppState) => state.benefit.selectedContractId;
export const getBenefitPriorities = (state: AppState) => state.benefit.priorities;
export const getSelectedBenefitTemplateId = (state: AppState) =>
    state.benefit.selectedBenefitTemplate;
export const getDateRangeBenefitTemplateDashboard = (state: AppState) =>
    state.benefit.benefitTemplateFilterDateRange;
export const getDateRangeBenefitPrioritiesDashboard = (state: AppState) =>
    state.benefit.benefitPrioritiesFilterDateRange;
export const getBenefitNotes = (state: AppState) => state.benefit.benefitNotes;
export const getBenefitNoteFormVisibility = (state: AppState) => state.benefit.formVisible;

export const filterArchived = <T extends Filters | BenefitTemplate>(data: T[]) =>
    data.filter(({ archived }) => !archived);

const getRoleNameById = (roles: Role[], userId: number) => {
    const role = roles.find(({ user }) => user === userId);

    const roleShortcut =
        role && Array.from(mapBenefitRoleShortcuts).find(([key]) => role.role === key);

    const roleName = roleShortcut && roleShortcut[1];

    return roleName;
};

export const getContractBenefitById = (data: ContractBenefit[], id: number) =>
    data.find((element) => element.id === id);

export const getBenefitById = (benefits: Benefit[], benefitId: number) =>
    benefits.find(({ id }) => id === benefitId);

export const getBenefitByRouteId = createSelector(
    getBenefits,
    extractRouteBenefitId,
    getBenefitById,
);

export const getBenefitTemplateByRouteId = createSelector(
    getBenefitTemplates,
    extractRouteBenefitId,
    (benefitTemplates: BenefitTemplate[], benefitTemplateId: number) =>
        benefitTemplates.find(({ id }) => id === benefitTemplateId),
);

export const getMyBenefitByRouteId = createSelector(
    getMyBenefits,
    extractRouteBenefitId,
    getBenefitById,
);

export const getContractBenefit = createSelector(
    getContractBenefits,
    extractRouteBenefitId,
    getContractBenefitById,
);

export const getAllBenefitCategories = createSelector(getBenefitCategories, (categories) =>
    categories.map(({ name, ...category }) => ({
        ...category,
        name: getName(name, category.archived),
    })),
);

export const getActiveBenefitCategories = createSelector(getBenefitCategories, filterArchived);

export const getActiveAndSelectedBenefitCategories = createSelector(
    getAllBenefitCategories,
    getBenefitByRouteId,
    (categories, benefit) =>
        benefit
            ? categories.filter(({ archived, id }) => !archived || id === benefit.category)
            : [],
);

export const getActiveBenefitTemplates = createSelector(getBenefitTemplates, filterArchived);

export const getUserBenefitRole = createSelector(getBenefitsRoles, getUserId, getRoleNameById);

export const getBenefitAssessors = createSelector(
    getAllEmployees,
    getBenefitsRoles,
    (employees, roles) => {
        if (employees.length === 0) {
            return [];
        }

        const assessors = roles.filter(({ role }) => role === BenefitRole.ASSESSOR);
        if (!assessors.length) {
            return [];
        }

        return assessors.map((assessor) => ({
            ...employees.find(({ user: { id } }) => assessor.user === id)!,
            id: assessor.id,
            organisationName: assessor.organisationName,
            userFullname: assessor.userFullname,
        }));
    },
);

const getOrganisationName = (organisations: Organisation[], role: Role) =>
    organisations.find(({ id }) => role.organisation === id)?.name || '';

export const getBenefitSuppliers = createSelector(
    getExecutives,
    getBenefitsRoles,
    (organisations, roles) => {
        if (organisations.length === 0) {
            return [];
        }
        const suppliers = roles.filter(
            ({ role, organisation, user }) =>
                role === BenefitRole.SUPPLIER && organisation && !user,
        );
        if (!suppliers.length) {
            return [];
        }

        return suppliers.map((supplier) => ({
            ...supplier,
            name: getOrganisationName(organisations, supplier),
        }));
    },
);

export const getBenefitIndividualSuppliers = createSelector(
    getExecutives,
    getBenefitsRoles,
    (organisations, roles) => {
        if (organisations.length === 0) {
            return [];
        }

        const suppliers = roles.filter(
            ({ role, organisation, user }) => role === BenefitRole.SUPPLIER && organisation && user,
        );
        return suppliers.map((supplier) => ({
            ...supplier,
            name: getOrganisationName(organisations, supplier),
            userFullname: supplier.userFullname,
        }));
    },
);

export const getBenefitIndividualPartners = createSelector(
    getExecutives,
    getBenefitsRoles,
    (organisations, roles) => {
        if (organisations.length === 0) {
            return [];
        }

        const partners = roles.filter(
            ({ role, organisation, user }) =>
                role === BenefitRole.DELIVERY_PARTNER && organisation && user,
        );

        return partners.map((partner) => ({
            ...partner,
            name: getOrganisationName(organisations, partner),
            userFullname: partner.userFullname,
        }));
    },
);

export const getBenefitPartners = createSelector(
    getExecutives,
    getBenefitsRoles,
    (organisations, roles) => {
        if (organisations.length === 0) {
            return [];
        }

        const partners = roles.filter(
            ({ role, organisation, user }) =>
                role === BenefitRole.DELIVERY_PARTNER && organisation && !user,
        );
        if (!partners.length) {
            return [];
        }

        return partners.map((partner) => ({
            ...partner,
            name: getOrganisationName(organisations, partner),
        }));
    },
);
