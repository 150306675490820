import { Antd3Form, Antd3Icon, StyledAntd3Form } from 'common/components/deprecated/antd3';
import { useState } from 'react';
import { Button, Table } from 'antd';
import { useSelector } from 'react-redux';

import { formatDisplayTimestamp, generateColumns } from 'common/helpers/utils';
import { getOrganisationSubscriptions } from '../selectors/userSelectors';
import { PaddedSection } from 'common/components';
import { Subscription } from '../models/userModel';
import { InvoiceList } from './InvoiceList';
import { RedirectButton } from './shared/Button';

export const SubscriptionsHistory = () => {
    const [isSubscriptionInvoiceListVisible, setIsSubscriptionInvoiceListVisible] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState<Subscription | undefined>(
        undefined,
    );
    const subscriptions = useSelector(getOrganisationSubscriptions);
    const subscriptionColumns = [
        {
            title: 'Id',
            key: 'id',
        },
        {
            title: 'Type',
            key: 'itemTitle',
        },
        {
            title: 'Payment Type',
            key: 'type',
        },
        {
            title: 'Number of Payment Periods',
            key: 'paymentPeriods',
        },
        {
            title: 'Created At',
            key: 'createdAt',
            render({ createdAt }: Subscription) {
                return formatDisplayTimestamp(createdAt);
            },
        },
        {
            title: 'Expired At',
            key: 'expiresAt',
            render({ expiresAt }: Subscription) {
                return formatDisplayTimestamp(expiresAt);
            },
        },
        {
            title: 'Invoices',
            key: 'id',
            render: (_: string, userSubscription: Subscription) => (
                <Button
                    size="small"
                    onClick={() => {
                        setIsSubscriptionInvoiceListVisible(true);
                        setSelectedSubscription(userSubscription);
                    }}
                    icon={<Antd3Icon type="folder-open" />}
                >
                    Open
                </Button>
            ),
        },
    ];

    if (isSubscriptionInvoiceListVisible && selectedSubscription) {
        return (
            <InvoiceList userSubscription={selectedSubscription}>
                <RedirectButton
                    onReset={() => setIsSubscriptionInvoiceListVisible(false)}
                    value="Back to Subscriptions"
                />
            </InvoiceList>
        );
    }

    return (
        <PaddedSection>
            <StyledAntd3Form header="Subscription History" bigHeader>
                <Antd3Form.Item>
                    <Table
                        columns={generateColumns(subscriptionColumns)}
                        dataSource={subscriptions}
                    />
                </Antd3Form.Item>
            </StyledAntd3Form>
        </PaddedSection>
    );
};
