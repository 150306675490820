import { useEffect, useState } from 'react';
import { Select, SelectProps, Form, FormItemProps } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select';
import { debounce, isNumber, isString } from 'lodash';
import { useDispatch } from 'react-redux';

import {
    AutocompleteField,
    Filters,
    RequestParams,
} from 'features/pagination/models/paginationModel';
import {
    autocompleteFailed,
    autocompleteSuccess,
} from 'features/pagination/actions/paginationActions';
import { generateAutocompleteEndpoint } from 'config/config';
import { defaultAutocompleteSize } from 'config/variables';
import { generateAutocompleteFilterProperties } from 'common/helpers/utils';
import { HttpService } from 'common/services/HttpService';
import { FilterData } from 'common/helpers/url';

import { InputLabelWithTooltip } from './components/InputLabelWithTooltip';
import { FormInputProps } from './models';

type Props = FormItemProps &
    Omit<SelectProps, 'onSelect'> &
    FormInputProps & {
        autocompleteField: AutocompleteField;
        searchParams?: Filters;
        requestParams?: RequestParams;
        onSelect?: (id: number, name: string) => void;
        filterOptionsIds?: number[];
        optionLabelParam?: string;
        optionLabelFormatter?: (result: any) => string;
    };

export const AutocompleteSelect = ({
    name,
    label,
    placeholder,
    required = true,
    requiredMessage,
    disabled = false,
    tooltip,
    help,
    validateStatus,
    autocompleteField,
    searchParams,
    onSelect,
    filterOptionsIds,
    optionLabelFormatter,
    initialValue,
    requestParams,
}: Props) => {
    const dispatch = useDispatch();
    const [httpService] = useState(new HttpService());
    const [options, setOptions] = useState<BaseOptionType[]>([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async (searchTerm = '') => {
        const path = generateAutocompleteEndpoint(autocompleteField, requestParams);

        if (!path) {
            return;
        }

        const filters = generateAutocompleteFilterProperties({
            autocompleteField,
            searchTerm,
            searchParams,
        });

        try {
            const { results } = await httpService.GET(
                path,
                { limit: defaultAutocompleteSize, offset: 0 },
                filters as FilterData,
            );
            setOptions(
                (results as any[]).map((result) => ({
                    value: result.id,
                    label: optionLabelFormatter ? optionLabelFormatter(result) : result?.name,
                })),
            );
            dispatch(
                autocompleteSuccess({
                    autocompleteField,
                    results,
                }),
            );
        } catch (error) {
            dispatch(
                autocompleteFailed({
                    autocompleteField,
                    message: `Error loading ${label}`,
                }),
            );
        }
    };

    const debouncedFetchData: (searchTerm: string) => void = debounce(
        (searchTerm) => fetchData(searchTerm),
        300,
    );

    const handleFocus = () => fetchData();

    const handleSearch = (value: string) => debouncedFetchData(value);

    const handleOnSelect = (_value: number, option: DefaultOptionType) => {
        if (onSelect && isNumber(option.value) && isString(option.children)) {
            onSelect(option.value, option.children);
        }
    };

    return (
        <Form.Item
            name={name}
            label={tooltip ? <InputLabelWithTooltip label={label} tooltip={tooltip} /> : label}
            help={help}
            validateStatus={validateStatus}
            rules={[{ required, message: requiredMessage || `${label} is required` }]}
        >
            <Select
                showSearch
                filterOption={false}
                onFocus={handleFocus}
                onSearch={handleSearch}
                onSelect={handleOnSelect}
                placeholder={label || placeholder}
                allowClear
                size="large"
                disabled={disabled}
                defaultValue={initialValue}
            >
                {(filterOptionsIds
                    ? options.filter((option) => !filterOptionsIds.includes(option.value))
                    : options
                ).map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                        {option.label}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};
