import { Collapse } from 'antd';
import { useDispatch } from 'react-redux';

import { SubmitButton } from 'common/components';
import { CollapseWrapper } from 'common/components/styled';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { editBenefitRequest } from 'features/benefit/actions';
import { EditStatusExplanation } from 'features/benefit/components/EditStatusExplanation';
import { Benefit } from 'features/benefit/models/benefitModels';
import { Contract } from 'features/contract/models/contractModels';
import { Antd3Form, StyledAntd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

interface BenefitStatusProps extends Antd3FormProps {
    benefit: Benefit;
    contract: Contract;
}

const EditBenefitStatusForm = ({ benefit, contract, form }: BenefitStatusProps) => {
    const dispatch = useDispatch();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        form.validateFields((error, { statusExplanation, ...values }) =>
            error
                ? showNotification({ text: notificationText.Error })
                : dispatch(
                      editBenefitRequest({
                          ...values,
                          benefitId: benefit.id,
                          contract: contract.id,
                          sharedBenefitForUrl: benefit.sharedBenefit,
                          ...(statusExplanation !== benefit.statusExplanation && {
                              statusExplanation,
                          }),
                      }),
                  ),
        );
    };

    return (
        <CollapseWrapper>
            <Collapse>
                <Collapse.Panel header="Update Benefit Status" key="status">
                    <StyledAntd3Form onSubmit={handleSubmit}>
                        <EditStatusExplanation
                            form={form}
                            benefitStatus={benefit.status}
                            benefitStatusExplanation={benefit.statusExplanation}
                            deliveredEmissionSavings={
                                benefit.deliveredEmissionSavings
                                    ? parseFloat(benefit.deliveredEmissionSavings)
                                    : undefined
                            }
                            deliveredPoints={
                                benefit.deliveredPoints
                                    ? parseFloat(benefit.deliveredPoints)
                                    : undefined
                            }
                            deliveredValue={
                                benefit.deliveredValue
                                    ? parseFloat(benefit.deliveredValue)
                                    : undefined
                            }
                        />
                        <SubmitButton value="Update Status" />
                    </StyledAntd3Form>
                </Collapse.Panel>
            </Collapse>
        </CollapseWrapper>
    );
};

export const EditBenefitStatus = Antd3Form.create({})(EditBenefitStatusForm);
