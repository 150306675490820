import * as React from 'react';
import { Button } from 'antd';

import { notArchivedFilterParam, notRootNodeFilterParam } from 'config/config';
import { NumberInputsRange } from 'common/components/deprecated/form/NumberInputsRange';
import { benefitMethod } from 'features/contract/models/contractModels';
import { benefitStatuses } from 'features/benefit/models/benefitModels';
import { supplierScoreOptions, assessorScoreOptions } from 'features/upload/models/uploadModels';
import { AutocompleteField, PaginationView } from 'features/pagination/models/paginationModel';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { FormFieldIds } from 'common/helpers/utils';
import { AutocompleteSelectAll } from 'features/pagination/components/AutocompleteSelectAll';
import { EditPaths } from 'features/edit/models/editModel';

import { BenefitsFilterContainerProps } from '../containers/BenefitsFilterContainer';
import { ResetButton } from './deprecated/form/ResetButton';
import { StyledAntd3Form, Antd3Form, Antd3FormProps } from './deprecated/antd3';
import { Input, PublishRadioButtons, RangePicker, Select } from './deprecated/form';
import { formatDate } from 'features/upload/helpers';

interface DownloadBenefitsState {
    priorities: string[];
    areAllPrioritiesSelected: boolean;
}

type BenefitsFilterProps = BenefitsFilterContainerProps & Antd3FormProps;

class BenefitsFilterComponent extends React.Component<BenefitsFilterProps, DownloadBenefitsState> {
    public state: DownloadBenefitsState = {
        priorities: [],
        areAllPrioritiesSelected: false,
    };

    public componentDidMount(): void {
        const { formInitial } = this.props;
        const { values } = formInitial || { values: {} };

        if (values.priorities) {
            this.setState({
                priorities: values.priorities,
            });
        }
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }

            const { priorities } = this.state;
            const { min_reported_on, max_reported_on } = values;
            this.props.saveFilters({
                paginatedView: this.props.filterId,
                values: {
                    ...values,
                    priorities,
                    min_reported_on: min_reported_on && formatDate(min_reported_on),
                    max_reported_on: max_reported_on && formatDate(max_reported_on),
                },
                path:
                    this.props.backUrl == EditPaths.MyBenefits
                        ? EditPaths.Benefits
                        : this.props.backUrl,
            });
        });
    };

    private handleSelectAllPriorities = (priorities: string[]) => {
        this.state.areAllPrioritiesSelected
            ? this.setState((prevState) => ({
                  priorities: [],
                  areAllPrioritiesSelected: !prevState.areAllPrioritiesSelected,
              }))
            : this.setState((prevState) => ({
                  priorities,
                  areAllPrioritiesSelected: !prevState.areAllPrioritiesSelected,
              }));
    };

    private addPriority = (selectedPriority: string) => {
        this.setState((prevState) => ({
            priorities: [...prevState.priorities, selectedPriority],
        }));
    };

    private removePriority = (selectedPriority: string) => {
        this.setState((prevState) => ({
            priorities: prevState.priorities.filter((priority) => priority !== selectedPriority),
        }));
    };

    public render(): JSX.Element {
        const { form, tenant, formInitial, clearFilters } = this.props;
        const isAssignEmployeesFilter = this.props.filterId === PaginationView.SupplierEditContract;
        const { values } = formInitial || { values: {} };

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} header="Edit Benefits Filter">
                <ResetButton
                    form={form}
                    clearFilters={clearFilters}
                    paginationView={this.props.filterId}
                />
                {!isAssignEmployeesFilter ? (
                    <>
                        <AutocompleteSelect
                            form={form}
                            autocompleteField={AutocompleteField.Contract}
                            defaultValue={values.contractTitle}
                            id={FormFieldIds.ContractTitle}
                            placeholder="Project Title"
                            notFoundContent="No projects found"
                        />
                        <AutocompleteSelect
                            form={form}
                            autocompleteField={AutocompleteField.Contract}
                            defaultValue={values.contractReferenceNumber}
                            id={FormFieldIds.ContractReferenceNumber}
                            placeholder="Project Reference Number"
                            notFoundContent="No reference number found"
                        />
                    </>
                ) : (
                    <>
                        <AutocompleteSelect
                            form={form}
                            autocompleteField={
                                AutocompleteField.ExecutiveAdminSearchContractsAssignBenefit
                            }
                            defaultValue={values.contractTitle}
                            id={FormFieldIds.ContractTitle}
                            placeholder="Project Title"
                            notFoundContent="No projects found"
                        />
                        <AutocompleteSelect
                            form={form}
                            autocompleteField={
                                AutocompleteField.ExecutiveAdminSearchContractsAssignBenefit
                            }
                            defaultValue={values.contractReferenceNumber}
                            id={FormFieldIds.ContractReferenceNumber}
                            placeholder="Project Reference Number"
                            notFoundContent="No reference number found"
                        />
                    </>
                )}

                {tenant && (
                    <>
                        <AutocompleteSelect
                            form={form}
                            autocompleteField={AutocompleteField.ContractCategory}
                            id={FormFieldIds.ContractCategory}
                            placeholder="Project Category"
                            notFoundContent="No project categories found"
                            defaultValue={values.contractCategory}
                        />
                        <AutocompleteSelect
                            autocompleteField={AutocompleteField.ContractPortfolioFilter}
                            form={form}
                            id={FormFieldIds.PortfolioFilter}
                            placeholder="Portfolio"
                            defaultValue={values.portfolioFilter}
                            searchParams={{ ...notRootNodeFilterParam, ...notArchivedFilterParam }}
                        />
                        <AutocompleteSelect
                            form={form}
                            autocompleteField={AutocompleteField.Department}
                            id={FormFieldIds.ContractDepartment}
                            placeholder="Department"
                            notFoundContent="No departments found"
                            defaultValue={values.contractDepartment}
                        />
                        <AutocompleteSelect
                            form={form}
                            autocompleteField={AutocompleteField.Contract}
                            id={FormFieldIds.FrameworkContract}
                            placeholder="Framework Project"
                            defaultValue={values.frameworkContract}
                            notFoundContent="No projects found"
                        />
                        <AutocompleteSelect
                            form={form}
                            autocompleteField={AutocompleteField.ContractManager}
                            id={FormFieldIds.ContractManager}
                            placeholder="Project Manager"
                            notFoundContent="No project managers found"
                            defaultValue={values.contractManager}
                            searchParams={notArchivedFilterParam}
                        />
                    </>
                )}
                {tenant && (
                    <PublishRadioButtons
                        form={form}
                        required={false}
                        defaultValue={values.published}
                    />
                )}
                <hr />
                <AutocompleteSelect
                    form={form}
                    id={FormFieldIds.Outcome}
                    placeholder="Benefit Outcome"
                    defaultValue={values.outcome}
                    autocompleteField={AutocompleteField.BenefitTemplate}
                    selectOnly={false}
                />
                <Input
                    form={form}
                    id="label"
                    label="Benefit Label"
                    initialValue={values.label}
                    required={false}
                />

                {tenant && (
                    <AutocompleteSelect
                        form={form}
                        autocompleteField={AutocompleteField.BenefitCategory}
                        id={FormFieldIds.Category}
                        placeholder="Benefit Category"
                        notFoundContent="No benefit categories found"
                        defaultValue={values.category}
                    />
                )}
                <Select
                    form={form}
                    id={FormFieldIds.Method}
                    placeholder="Benefit Model"
                    options={benefitMethod}
                    required={false}
                    initialValue={values.method}
                    allowClear
                />
                {tenant && (
                    <AutocompleteSelect
                        form={form}
                        autocompleteField={AutocompleteField.Location}
                        id={FormFieldIds.Location}
                        placeholder="Benefit Location"
                        notFoundContent="No locations found"
                        defaultValue={values.location}
                    />
                )}
                <NumberInputsRange
                    form={form}
                    fromInput={{ id: FormFieldIds.PointsMin, placeholder: 'Minimum points' }}
                    toInput={{ id: FormFieldIds.PointsMax, placeholder: 'Maximum points' }}
                    label="Benefit Points"
                    initFrom={values.pointsMin}
                    initTo={values.pointsMax}
                />
                <NumberInputsRange
                    form={form}
                    fromInput={{ id: FormFieldIds.ValueMin, placeholder: 'Minimum value' }}
                    toInput={{ id: FormFieldIds.ValueMax, placeholder: 'Maximum value' }}
                    label="Benefit Value"
                    initFrom={values.valueMin}
                    initTo={values.valueMax}
                />
                <NumberInputsRange
                    form={form}
                    fromInput={{
                        id: FormFieldIds.EmissionSavingsMin,
                        placeholder: 'Minimum emission savings',
                    }}
                    toInput={{
                        id: FormFieldIds.EmissionSavingsMax,
                        placeholder: 'Maximum emission savings',
                    }}
                    label="Emissions Savings"
                    initFrom={values.emissionSavingsMin}
                    initTo={values.emissionSavingsMax}
                />
                {tenant && (
                    <AutocompleteSelectAll
                        form={form}
                        autocompleteField={AutocompleteField.BenefitPriorities}
                        id={FormFieldIds.Priorities}
                        placeholder="Benefit Priorities"
                        notFoundContent="No categories found"
                        defaultValue={values.priorities}
                        handleSelectAllPriorities={this.handleSelectAllPriorities}
                        multiple
                        areAllPrioritiesSelected={this.state.areAllPrioritiesSelected}
                        selected={this.state.priorities}
                        addPriority={this.addPriority}
                        removePriority={this.removePriority}
                    />
                )}
                {!tenant && (
                    <AutocompleteSelect
                        form={form}
                        autocompleteField={AutocompleteField.TenantOrganisation}
                        id={FormFieldIds.Organisation}
                        placeholder="Buyer"
                        notFoundContent="No buyer found"
                        defaultValue={values.organisation}
                    />
                )}
                {tenant && (
                    <AutocompleteSelect
                        form={form}
                        autocompleteField={AutocompleteField.Assessor}
                        id={FormFieldIds.Assessor}
                        placeholder="Contributor"
                        notFoundContent="No contributors found"
                        defaultValue={values.assessor}
                        searchParams={notArchivedFilterParam}
                    />
                )}
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.SupplierOrganisation}
                    id={FormFieldIds.Supplier}
                    placeholder="Supplier"
                    notFoundContent="No suppliers found"
                    defaultValue={values.supplier}
                    searchParams={notArchivedFilterParam}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.ExecutiveSupplierUser}
                    id={FormFieldIds.SupplierExecutive}
                    placeholder="Supplier Executives"
                    notFoundContent="No executives with supplier role found"
                    defaultValue={values.supplierExecutive}
                    searchParams={notArchivedFilterParam}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.DeliveryPartnerOrganisation}
                    id={FormFieldIds.DeliveryPartner}
                    placeholder="Delivery Partner"
                    notFoundContent="No delivery partners found"
                    defaultValue={values.deliveryPartner}
                    searchParams={notArchivedFilterParam}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.ExecutiveDeliveryPartnerUser}
                    id={FormFieldIds.DeliveryPartnerExecutive}
                    placeholder="Delivery Partner Executives"
                    notFoundContent="No executives with delivery partners role found"
                    defaultValue={values.deliveryPartnerExecutive}
                    searchParams={notArchivedFilterParam}
                />
                <RangePicker
                    form={form}
                    label="Benefit Start Date"
                    inputs={[
                        {
                            id: FormFieldIds.MinStartDate,
                            placeholder: 'From',
                            initialValue: values.minStartDate,
                        },
                        {
                            id: FormFieldIds.MaxStartDate,
                            placeholder: 'To',
                            initialValue: values.maxStartDate,
                        },
                    ]}
                />
                <RangePicker
                    form={form}
                    label="Benefit Completion Date"
                    inputs={[
                        {
                            id: FormFieldIds.MinEndDate,
                            placeholder: 'From',
                            initialValue: values.minEndDate,
                        },
                        {
                            id: FormFieldIds.MaxEndDate,
                            placeholder: 'To',
                            initialValue: values.maxEndDate,
                        },
                    ]}
                />

                <RangePicker
                    form={form}
                    label="Benefit Closure Date"
                    inputs={[
                        {
                            id: FormFieldIds.min_reported_on,
                            placeholder: 'From',
                            initialValue: values.minStartDate,
                        },
                        {
                            id: FormFieldIds.max_reported_on,
                            placeholder: 'To',
                            initialValue: values.maxStartDate,
                        },
                    ]}
                />
                <Select
                    form={form}
                    id={FormFieldIds.Status}
                    placeholder="Benefit Status"
                    options={benefitStatuses}
                    required={false}
                    initialValue={values.status}
                    allowClear
                />
                <Button type="primary" htmlType="submit">
                    Filter
                </Button>
            </StyledAntd3Form>
        );
    }
}

export const BenefitsFilter = Antd3Form.create({})(BenefitsFilterComponent);
