import * as React from 'react';
import { Divider } from 'antd';
import { connect } from 'react-redux';

import { InnerCard } from 'common/components/styled';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteData, AutocompleteField } from 'features/pagination/models/paginationModel';
import { Organisation } from 'features/organisation/models/organisationModels';
import { selectBenefitContract } from 'features/benefit/actions';
import {
    autocompleteRequest,
    clearInitialValueData,
    getInitialValueRequest,
} from 'features/pagination/actions/paginationActions';
import { selectContractOrganisation } from 'features/contract/actions/contractActions';
import { RolesList } from 'common/components';
import { InviteUserModal } from 'features/invite/components/InviteUserModal';
import { DeleteOrganisationButton } from 'features/create/components/DeletePartnerButton';
import { BenefitRole } from 'features/benefit/models/benefitModels';
import { InviteByEmailWithRole } from 'features/invite/models/inviteModel';
import { NewUserRolesList } from 'features/invite/components/NewUserRolesList';
import { Antd3FormProps } from 'common/components/deprecated/antd3';

interface AutocompleteDispatchProps {
    autocompleteRequest: typeof autocompleteRequest;
    getInitialValueRequest: typeof getInitialValueRequest;
    clearInitialValueData: typeof clearInitialValueData;
    selectContractOrganisation: typeof selectContractOrganisation;
    selectBenefitContract: typeof selectBenefitContract;
}

interface AutocompleteSelectionProps {
    newSupplierUsers: InviteByEmailWithRole[];
    selectNewSupplierUser: (employeeData: InviteByEmailWithRole) => void;
    deleteNewSupplierUser: (employeeData: InviteByEmailWithRole) => void;
    selectedSupplier?: Organisation;
    selectSupplierOrganisation: (selectedSupplier: Organisation) => void;
    deleteSupplierOrganisation: (supplierId: number) => void;
    selectedSupplierUsers: AutocompleteData[];
    selectSupplierUser: (selectedUser: AutocompleteData) => void;
    deleteSupplierUser: (userId: number) => void;
    selectedInvitePendingUsers: AutocompleteData[];
    selectInvitePendingSupplierUser: (selectedSupplier: AutocompleteData) => void;
    deleteInvitePendingSupplierUser: (supplierId: number) => void;
    tooltip?: string;
}

class SupplierFields extends React.Component<
    AutocompleteSelectionProps & AutocompleteDispatchProps & Antd3FormProps
> {
    public render(): JSX.Element {
        const {
            form,
            selectedSupplier,
            selectedSupplierUsers,
            selectedInvitePendingUsers,
            newSupplierUsers,
            selectNewSupplierUser,
            deleteNewSupplierUser,
            tooltip,
        } = this.props;

        return (
            <InnerCard>
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.ExecutiveOrganisation}
                    id="suppliers"
                    placeholder="Choose Supplier Organisation"
                    notFoundContent="No Supplier organisations found"
                    getAutocompleteData={this.props.selectSupplierOrganisation}
                    tooltip={tooltip}
                    dataTestAttribute="suppliers-select"
                />
                {selectedSupplier ? (
                    <>
                        <AutocompleteSelect
                            form={form}
                            autocompleteField={AutocompleteField.BenefitRoleExecutives}
                            id="individual_suppliers"
                            placeholder="Add Existing Supplier Users"
                            notFoundContent="No users found"
                            getAutocompleteData={this.props.selectSupplierUser}
                            disabled={selectedSupplier == undefined}
                            searchParams={{
                                organisation: selectedSupplier.id,
                            }}
                        />
                        <AutocompleteSelect
                            form={form}
                            autocompleteField={AutocompleteField.BenefitRoleInvitedExecutives}
                            id="invited_individual_suppliers"
                            placeholder="Add Invited Supplier Users"
                            notFoundContent="No users with pending invites"
                            getAutocompleteData={this.props.selectInvitePendingSupplierUser}
                            disabled={selectedSupplier == undefined}
                            searchParams={{
                                organisation: selectedSupplier.id,
                            }}
                        />
                        <InviteUserModal
                            form={form}
                            onInvite={selectNewSupplierUser}
                            organisationName={selectedSupplier.name}
                            organisationId={selectedSupplier.id}
                            role={BenefitRole.SUPPLIER}
                        />
                        <Divider orientation="left">Active Roles</Divider>
                        <RolesList
                            items={selectedSupplierUsers.map((user) => ({
                                id: user ? user.id : '',
                                name: user && user.email ? user.email : 'User email not found',
                            }))}
                            deleteItem={this.props.deleteSupplierUser}
                            noData="No supplier users added"
                        />
                        <Divider orientation="left">Pending Roles</Divider>
                        <RolesList
                            items={selectedInvitePendingUsers.map((user) => ({
                                id: user ? user.id : '',
                                name:
                                    user && user.email
                                        ? user.email + ' - invite pending'
                                        : 'User email not found',
                            }))}
                            deleteItem={this.props.deleteInvitePendingSupplierUser}
                            noData="No invite pending supplier users added"
                        />
                        <NewUserRolesList
                            items={newSupplierUsers
                                .filter(
                                    (user) =>
                                        user.organisationId == selectedSupplier.id &&
                                        user.role == BenefitRole.SUPPLIER,
                                )
                                .map((user) => ({
                                    id: user,
                                    name: user.email || '',
                                }))}
                            deleteItem={deleteNewSupplierUser}
                            noData="No new users invited"
                        />
                        <DeleteOrganisationButton
                            onClick={() =>
                                this.props.deleteSupplierOrganisation(selectedSupplier.id)
                            }
                        />
                    </>
                ) : null}
            </InnerCard>
        );
    }
}

export const SupplierForm = connect(null, {
    autocompleteRequest,
    getInitialValueRequest,
    clearInitialValueData,
    selectContractOrganisation,
    selectBenefitContract,
})(SupplierFields);
