import { Antd3Form, Antd3Icon, StyledAntd3Form } from 'common/components/deprecated/antd3';
import { useEffect } from 'react';
import { Button, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { generateColumns } from 'common/helpers/utils';
import { Props } from '../containers/SubscriptionContainer';
import { getSubscriptionInvoicesRequest } from '../actions/userActions';
import { PaddedSection } from 'common/components';
import { Invoice } from '../models/userModel';
import {
    downloadInvoicePdfRequest,
    clearInvoiceUrl,
} from 'features/download/actions/downloadActions';
import { getSubscriptionInvoices } from '../selectors/userSelectors';
import { getDownloadedInvoiceUrl } from 'features/download/selectors/downloadSelectors';

export const InvoiceList = ({ userSubscription, children }: Props) => {
    const dispatch = useDispatch();
    const downloadedInvoiceUrl = useSelector(getDownloadedInvoiceUrl);
    const myInvoices = useSelector(getSubscriptionInvoices);
    const subscriptionId = userSubscription?.id;
    const invoiceColumns = [
        { title: 'Invoice Number', key: 'invoiceNumber' },
        {
            title: 'Issue Date',
            key: 'issuedAt',
        },
        {
            title: 'Due Date',
            key: 'dueAt',
        },
        {
            title: 'Amount Due',
            key: 'amountDue',
        },
        {
            title: 'Amount Paid ',
            key: 'amountPaid',
        },
        {
            title: 'Total',
            key: 'total',
        },
        {
            title: '',
            key: 'id',
            render: (_: string, { id }: Invoice) => (
                <Button
                    onClick={() => handleInvoiceDownload(subscriptionId, id)}
                    size="small"
                    icon={<Antd3Icon type="download" />}
                >
                    Download
                </Button>
            ),
        },
    ];

    const handleInvoiceDownload = (subscriptionId: number, invoiceId: number) => {
        dispatch(downloadInvoicePdfRequest({ subscriptionId, invoiceId }));
    };

    useEffect(() => {
        dispatch(getSubscriptionInvoicesRequest(subscriptionId));
    }, [userSubscription]);

    useEffect(() => {
        if (!downloadedInvoiceUrl) {
            return;
        }
        const newWindow = window.open(downloadedInvoiceUrl, '_blank');

        if (newWindow) {
            newWindow.focus();
        } else {
            console.error('Pop-up was blocked. Please allow pop-ups for this site.');
        }

        URL.revokeObjectURL(downloadedInvoiceUrl);
        dispatch(clearInvoiceUrl());
    }, [downloadedInvoiceUrl]);

    return (
        <PaddedSection>
            <StyledAntd3Form header="My Invoices" bigHeader>
                <Antd3Form.Item>
                    {children}
                    <Table columns={generateColumns(invoiceColumns)} dataSource={myInvoices} />
                </Antd3Form.Item>
            </StyledAntd3Form>
        </PaddedSection>
    );
};
